import { Tabs } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import styled from 'styled-components';
import TollStatus from './TollStatus/TollStatus';
import Comments from './Comments/Comments';
import Documents from './Documents/Documents';
import Logs from './Logs/Logs';
import TollSpecific from './TollSpecific/TollSpecific';

const StyledTabs = styled(Tabs)`
  flex-grow: 1;
  height: 100%;

  .shell-tabs-nav {
    .shell-tabs-tab-btn {
      font-weight: 600;
    }
  }

  .shell-tabs-content-holder {
    background-color: transparent;
    overflow: auto;
  }
  .shell-tabs-nav-list {
    gap: 10px;
  }
`;

type TollInformationProps = {
  type: string;
  id: string;
  colcoCountryTypeId?: string;
};

const TollInformation: React.FC<TollInformationProps> = ({
  type,
  id,
  colcoCountryTypeId
}) => {
  const items = [
    {
      label: 'Toll Specific',
      key: 'tollSpecific',
      children: <TollSpecific type={type} id={id} />,
      supportedTypes: ['customer', 'contact', 'vehicle', 'obu', 'contract']
    },
    {
      label: 'Toll Status',
      key: 'tollStatus',
      children: <TollStatus type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'card'
      ]
    },
    {
      label: 'Log',
      key: 'log',
      children: <Logs type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'address',
        'card'
      ]
    },
    {
      label: 'Comments',
      key: 'comments',
      children: <Comments type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'address',
        'card'
      ]
    },
    {
      label: 'Documents',
      key: 'documents',
      children: (
        <Documents
          type={type}
          id={id}
          colcoCountryTypeId={colcoCountryTypeId}
        />
      ),
      supportedTypes: ['customer', 'vehicle']
    }
  ];
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full h-full"
      data-testid="toll-info"
    >
      <StyledTabs
        destroyInactiveTabPane
        items={items.filter((item) => item.supportedTypes.includes(type))}
      />
    </div>
  );
};

export default TollInformation;
