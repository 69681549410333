import { ColumnDef, Row } from '@tanstack/react-table';
import QueryError from 'components/QueryError';
import { STALE_TIME } from 'react-query';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import { useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { ExpandableTable } from 'components/Table/ExpandableTable';

interface CardUsageProps {
  cardID?: string;
  customerId?: string;
}

interface RowData {
  id: string;
  tollName: string;
  typeName: string;
  sign: string;
}
const DEFAULT_PAGE_SIZE = 20;

function CardUsage({ cardID, customerId }: Readonly<CardUsageProps>) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handleDoubleClick = (row: Row<RowData>) => {
    let type = '';
    const cardType = row.original.typeName;

    if (cardType === 'Contract') {
      type = 'contract';
    }

    if (cardType === 'Vehicle') {
      type = 'vehicle';
    }

    if (type === '') {
      return;
    }

    window.open(
      `/customer/${customerId}/${row.original.id}?type=${type}`,
      '_blank'
    );
  };

  const { data, isLoading, isError } = useQuery(
    ['CARD_USAGE', cardID],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.cardUsage,
        {
          pageNumber,
          pageSize,
          cardID
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always',
      enabled: !!cardID
    }
  );
  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };
  const paginationProps = useSdsPagination(handleChangePagination);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col mt-2  h-full row overflow-y-auto gap-2">
        <QueryError isLoading={isLoading} isError={isError}>
          <ExpandableTable
            data={data?.data ?? []}
            columns={
              [...getOverviewColumns('CardUsage')] as ColumnDef<RowData>[]
            }
            columnSelection={false}
            paginationData={{
              ...paginationProps,
              total: data?.count
            }}
            exportEnabled={false}
            onRowDoubleClick={handleDoubleClick}
          />
        </QueryError>
      </div>
    </div>
  );
}

export default CardUsage;
