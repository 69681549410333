import CheckBox from 'components/FormCmponents/CheckBox';
import SelectField from 'components/FormCmponents/SelectField';
import { Field } from 'formik';
import { useAuth } from 'auth/Authorization';
import ParticlateFilters from 'utils/StaticData/Vehicle/particulateFilters.json';

export const OtherEquipment = () => {
  const { verifyRoles, roles } = useAuth();

  return (
    <>
      <Field
        id="particulateFilterClass"
        name="particulateFilterClass"
        label="Particulate filter class V.5"
        labelPosition="left"
        placeholder="Select"
        component={SelectField}
        options={ParticlateFilters.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="metallizedWindshield"
        name="metallizedWindshield"
        label="Metallized windshield"
        labelPosition="left"
        component={CheckBox}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};
