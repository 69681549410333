import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sizes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import { queryClient } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import dayjs from 'dayjs';
import VehicleSelection from './components/VehicleSelection';
import { NewOBU } from '../types';
import TollSelection from './components/TollSelection';
import ContractSelection from './components/ContractSelection';
import FranceSelection, {
  subscriptionFields
} from './components/FranceSelection';

let TOTAL_STEPS = 3;
const STEP_3 = 3;

const FRANCE_TOLL_ID = '6b81fa1a-36f8-4ef9-adef-6c1866c7451a';

const StyledModal = styled(Modal)`
  .shell-modal-container-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface AddOBUProps {
  customerId?: string;
  disabled?: boolean;
}

const getTitles = (step: number) => {
  switch (step) {
    case 1:
      return 'Select Toll';
    case 2:
      return 'Select Contract';
    case STEP_3:
      return 'Select Vehicle';
    case 4:
      return 'France Subscription';
    default:
      return 'New OBU';
  }
};

const AddObu: React.FC<AddOBUProps> = ({ customerId, disabled = false }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<NewOBU>>(null);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleOnStepClick = (num: number) => {
    setStep(num);
  };

  const handleOnClose = () => {
    setStep(1);
    setOpen(false);
  };

  const nextDisabled = (values: NewOBU) => {
    switch (step) {
      case 1:
        return !values.tollID;
      case 2:
        return !values.contractID;
      case STEP_3:
        return !values.vehicleID;
      default:
        return false;
    }
  };

  const submitDisbled = (values: NewOBU) => {
    if (step === STEP_3) {
      return !values.vehicleID;
    }

    if (step === 4) {
      return !values.subscriptionType;
    }
    return false;
  };

  useEffect(() => {
    const { current } = formRef;
    const values = current?.values;
    if (values?.tollID === FRANCE_TOLL_ID) {
      TOTAL_STEPS = 4;
    } else {
      TOTAL_STEPS = STEP_3;
    }
  }, [formRef.current?.values]);

  const handleSave = (values: NewOBU, formikHelpers: FormikHelpers<NewOBU>) => {
    const { setSubmitting } = formikHelpers;

    const payload = { ...values };

    let endpoint = customerEndPoints.updateObu;

    if (step === 4) {
      delete payload.tollName;
      endpoint = customerEndPoints.saveOBUFrance;
    }

    customerInstance
      .post(endpoint, {
        ...payload,
        effectiveDate: dayjs().toISOString()
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['search-obu-by-customer']
        });
        successDialog('', 'OBU added successfully');
        setOpen(false);
        setStep(1);
      })
      .catch((error) => {
        const message = 'Failed to add OBU.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleOnClickBack = (stepNum: number) => {
    switch (stepNum) {
      case 2:
        formRef.current?.setFieldValue('contractID', undefined);
        formRef.current?.setFieldValue('tollID', undefined);
        formRef.current?.setFieldValue('tollName', undefined);
        break;
      case STEP_3:
        formRef.current?.setFieldValue('vehicleID', undefined);
        formRef.current?.setFieldValue('vehicleNo', undefined);
        formRef.current?.setFieldValue('contractID', undefined);
        break;
      case 4:
        subscriptionFields.forEach((key) => {
          formRef.current?.setFieldValue(key, undefined);
        });
        formRef.current?.setFieldValue('vehicleID', undefined);
        formRef.current?.setFieldValue('vehicleNo', undefined);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        icon={<Icons.Add />}
        iconPosition="left"
        data-testid="add-new-obu"
        onClick={() => setOpen(true)}
        disabled={disabled}
      >
        New OBU
      </Button>
      {open && (
        <Formik<NewOBU>
          initialValues={{}}
          enableReinitialize
          validate={(values) => {
            const errors: Partial<NewOBU> = {};
            if (step === 4 && !values.subscriptionType) {
              errors.subscriptionType = 'Please select subscription type';
            }
            return errors;
          }}
          onSubmit={handleSave}
          innerRef={formRef}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <StyledModal
              title={getTitles(step)}
              open={open}
              width={step === 4 ? '40%' : '90%'}
              size={Sizes.Small}
              maskClosable={false}
              mask
              onClose={handleOnClose}
              contentScrollable
              actionsAlignment={Alignments.Right}
              centered
              actions={[
                {
                  label: 'Back',
                  action: () => {
                    handleOnClickBack(step);
                    handleOnStepClick(step - 1);
                  },
                  props: {
                    variant: Variants.Outlined,
                    icon: <Icons.ArrowLeft />,
                    hidden: step === 1,
                    style: {
                      display: step === 1 ? 'none' : ''
                    }
                  }
                },
                {
                  label: 'Next',
                  action: () => {
                    handleOnStepClick(step + 1);
                  },
                  props: {
                    icon: <Icons.ArrowRight />,
                    disabled: nextDisabled(values),
                    iconPosition: 'right',
                    hidden: step === TOTAL_STEPS,
                    style: {
                      display: step === TOTAL_STEPS ? 'none' : ''
                    }
                  }
                },
                {
                  label: 'Submit',
                  action: () => {
                    handleSubmit();
                  },
                  props: {
                    icon: <Icons.Save />,
                    disabled: isSubmitting || submitDisbled(values),
                    type: 'submit',
                    hidden: step !== TOTAL_STEPS,
                    style: {
                      display: step !== TOTAL_STEPS ? 'none' : ''
                    },
                    'aria-hidden': step !== TOTAL_STEPS
                  }
                }
              ]}
            >
              <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
                {step === 1 && <TollSelection customerId={customerId ?? ''} />}
                {step === 2 && (
                  <ContractSelection customerId={customerId ?? ''} />
                )}
                {step === 3 && (
                  <VehicleSelection customerId={customerId ?? ''} />
                )}
                {step === 4 && <FranceSelection />}
              </div>
            </StyledModal>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddObu;
