import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sentiments,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { ChangeEvent, useRef, useState } from 'react';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FormikErrors
} from 'formik';
import NumberField from 'components/FormCmponents/NumberField';
import DatePicker from 'components/FormCmponents/DatePicker';
import { BEError, CzechTollCardExpired } from 'utils/Interfaces';
import dayjs from 'dayjs';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { axiosInstance } from 'api';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import { AxiosError } from 'axios';
import { useAuth } from 'auth/Authorization';

interface FormValues {
  accountCardNumber: string;
  accountCardNumberNew: string;
  expiryDateNew: string;
  vehicleLpn?: string;
}

const CzechCardChange = ({
  selectedRows,
  onSuccess
}: {
  selectedRows: Array<unknown>;
  onSuccess?: () => void;
}) => {
  const { verifyRoles, roles } = useAuth();
  const [open, setOpen] = useState(false);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const handleOnClose = () => {
    setOpen(false);
    formRef.current?.resetForm();
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    const MAX_LENGTH = 19;

    if (!values.accountCardNumberNew) {
      errors.accountCardNumberNew = 'Please fill out this mandatory field';
    } else if (values.accountCardNumberNew.length < MAX_LENGTH) {
      errors.accountCardNumberNew = 'Please enter 19 digits';
    } else if (
      !values.accountCardNumberNew.startsWith('7002') &&
      !values.accountCardNumberNew.startsWith('7077')
    ) {
      errors.accountCardNumberNew =
        'Please enter a valid card number starting with 7002 or 7077';
    }

    if (!values.expiryDateNew) {
      errors.expiryDateNew = 'Please fill out this mandatory field';
    }

    return errors;
  };

  const initialValues: FormValues = {
    accountCardNumber: (selectedRows[0] as CzechTollCardExpired)
      ? (selectedRows[0] as CzechTollCardExpired).cardNumber
      : '',
    vehicleLpn: (selectedRows[0] as CzechTollCardExpired)
      ? (selectedRows[0] as CzechTollCardExpired).vehicleLicensePlates
      : '',
    accountCardNumberNew: '',
    expiryDateNew: ''
  };

  const handleConfirm = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    let payload = values;

    if (values.accountCardNumber === values.accountCardNumberNew) {
      const isConfirmed = await confirmCloseDialog(
        'Are you sure you want to update the same card number?',
        `New Card Expiry Date will be updated for all the Vehicle LPN associated with this card`
      );
      if (!isConfirmed) {
        setSubmitting(false);
        return;
      }
      const { vehicleLpn, ...rest } = values;
      payload = { ...rest };
    }

    axiosInstance
      .post(actionButtonEndpoints.insertCardChangeRequests, [payload], {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        successDialog('', 'Record updated successfully');
        setSubmitting(false);
        handleOnClose();
        onSuccess?.();
      })
      .catch((error) => {
        let message = 'Failed to update record, please try again later';
        const axiosError = error as AxiosError;

        const { messages } = axiosError.response?.data as BEError;
        if (messages && Array.isArray(messages) && messages.length) {
          const result = messages.map((msg: string) => `<li>${msg}</li>`);
          message = `<ul class="list-disc 
          list-outside p-2 m-2">${result.join('')}</ul>`;
        }
        errorDialog('', message);
      });
  };

  // function to convert MM/YYYY format date to Date object
  const convertToDate = (dateString: string) => {
    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[0], 10);
    return new Date(year, month - 1);
  };

  const disabledDate = (current: dayjs.Dayjs) =>
    current && current < dayjs().endOf('day');

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, maxLength } = event.target;
    const v = maxLength > 0 ? value.slice(0, maxLength) : value;
    formRef.current?.setFieldValue(name, v);
  }

  return (
    <div>
      <Button
        data-testid="changeButton"
        disabled={!selectedRows.length || verifyRoles([roles.SOTR_VIEW])}
        onClick={() => setOpen(true)}
        id="blockbtn"
        sentiment={Sentiments.Positive}
        icon={<Icons.SwapHorizontal />}
        variant="outlined"
        size="small"
      >
        Change
      </Button>
      {!!selectedRows.length && (
        <Modal
          width="40%"
          title="Update card"
          open={open}
          size={Sizes.Small}
          maskClosable={false}
          mask
          onClose={handleOnClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          bodyPadding={false}
        >
          <div className="p-5">
            <Formik<FormValues>
              initialValues={initialValues}
              onSubmit={handleConfirm}
              innerRef={formRef}
              enableReinitialize
              validate={validate}
            >
              {({ values, setFieldValue, isValid, dirty }) => (
                <Form>
                  <Field
                    id="accountCardNumber"
                    name="accountCardNumber"
                    readOnly
                    placeholder="Type here"
                    label="Old  Card Number"
                    component={NumberField}
                  />
                  <Field
                    id="accountCardNumberNew"
                    name="accountCardNumberNew"
                    placeholder="Type here"
                    label="New Card Number"
                    component={NumberField}
                    maxLength={19}
                    onChange={handleChange}
                  />
                  <Field
                    id="expiryDateNew"
                    name="expiryDateNew"
                    placeholder="YYYY/MM"
                    format="YYYY/MM"
                    label="New card Expiry Date"
                    picker="month"
                    component={DatePicker}
                    onChange={(v: dayjs.Dayjs | null) => {
                      setFieldValue('expiryDateNew', v?.format('YYYY/MM'));
                    }}
                    value={
                      values.expiryDateNew
                        ? dayjs(convertToDate(values.expiryDateNew))
                        : null
                    }
                    disabledDate={disabledDate}
                  />
                  <div className="flex justify-end">
                    <Button
                      type="submit"
                      disabled={!(isValid && dirty)}
                      size="small"
                      data-testid="submit-button"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CzechCardChange;
