import React, { useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import {
  Alert,
  Button,
  Icons,
  Sentiments
} from '@sede-x/shell-ds-react-framework';
import { queryClient, STALE_TIME } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import TollInformation from 'components/TollInformation/TollInformation';
import { useAuth } from 'auth/Authorization';
import { Contract } from '../types';
import ContractForm from '../ContractForm/ContractForm';

interface ContractDetailsProps {
  contractID?: string;
  onSuccess?: () => void;
}

async function fetchContractById(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.contractDetails,
    {
      contractId: id
    }
  );
  return data;
}

const CONTRACTS_LIST_QUERY_KEY = 'customercontracts-list';

const ContractDetails: React.FC<ContractDetailsProps> = ({
  contractID,
  onSuccess
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedTab, setSelectedTab] = useState<string>('ContractDetails');
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: contractData,
    isLoading
  } = useQuery({
    queryKey: ['contract-details', contractID],
    queryFn: () => fetchContractById(contractID ?? ''),
    staleTime: STALE_TIME,
    enabled: !!contractID && selectedTab === 'ContractDetails',
    refetchOnWindowFocus: false
  });
  const validate = (values: Contract) => {
    const errors: FormikErrors<Contract> = {};
    if (values.tollName === 'T4E') {
      if (!values.rentalFee1) {
        errors.rentalFee1 = 'Please fill out this mandatory field';
      }

      if (!values.rentalFeeNP) {
        errors.rentalFeeNP = 'Please fill out this mandatory field';
      }
    }

    return errors;
  };
  const handleSave = (
    values: Contract,
    formikHelpers: FormikHelpers<Contract>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.saveContract, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customercontracts-list']
        });
        queryClient.invalidateQueries({
          queryKey: ['contract-details']
        });
        queryClient.invalidateQueries({
          queryKey: ['rental-period-lists']
        });
        successDialog('', 'Contract updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to update Contract details.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Contract',
      'Are you sure you want to delete this Contract?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteContract, { contractID })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [CONTRACTS_LIST_QUERY_KEY]
        });
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'Contract deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete Contract.';
        errorDialog('Error', errorHelper(error, message));
      });
  };

  const items = [
    {
      label: 'CONTRACT DETAILS',
      key: 'ContractDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={contractData?.data}
              enableReinitialize
              onSubmit={handleSave}
              validate={validate}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      hidden={
                        !(
                          contractData?.data?.tollName === 'T4E' ||
                          contractData?.data?.tollName === 'Telepass'
                        )
                      }
                      data-testid="save-contract"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-contract"
                      disabled={verifyRoles([
                        roles.SOTR_VIEW,
                        roles.SOTR_MANAGE
                      ])}
                    >
                      Delete
                    </Button>
                  </div>
                  {contractData?.data?.cardNoIsBlocked && (
                    <Alert
                      sentiment={Sentiments.Negative}
                      icon={<Icons.AlertCircle />}
                      solidBgColor
                    >
                      ATTENTION: The contract card number is blocked
                    </Alert>
                  )}
                  <ContractForm contractData={contractData?.data} />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation type="contract" id={contractID ?? ''} />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full"
      data-testid="contract-details"
    >
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default ContractDetails;
