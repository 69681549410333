import { useAuth } from 'auth/Authorization';
import TextField from 'components/FormCmponents/TextField';
import { Label } from 'components/Label/Label';
import { Field } from 'formik';
import React from 'react';

const ContactDetails: React.FC = () => {
  const { verifyRoles, roles } = useAuth();

  return (
    <>
      <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
        <div>
          <Label text="Phone" required />
        </div>
        <div className="grid grid-cols-5 gap-3 col-span-2">
          <Field
            id="phoneCountryCode"
            name="phoneCountryCode"
            component={TextField}
            className
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <div className="col-span-4">
            <Field
              id="phone"
              name="phone"
              placeholder="Type here"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
        <div>
          <Label text="Mobile" required />
        </div>
        <div className="grid grid-cols-5 gap-3 col-span-2">
          <Field
            id="mobileCountryCode"
            name="mobileCountryCode"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <div className="col-span-4">
            <Field
              id="mobile"
              name="mobile"
              placeholder="Type here"
              component={TextField}
              readOnly={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </div>
      </div>
      <Field
        id="mail"
        name="mail"
        placeholder="Type here"
        label="Email"
        labelPosition="left"
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};

export default ContactDetails;
