import CheckBox from 'components/FormCmponents/CheckBox';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field, useFormikContext } from 'formik';
import { useAuth } from 'auth/Authorization';
import typeLpn from 'utils/StaticData/Vehicle/typeLpn.json';
import { Vehicle } from '../types';

export const ShellConsortium = () => {
  const { verifyRoles, roles } = useAuth();
  const { values } = useFormikContext<Vehicle>();
  return (
    <>
      <Field
        id="vehicleNotOwned"
        name="vehicleNotOwned"
        label="Vehicle not owned by customer"
        labelPosition="left"
        component={CheckBox}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      {values?.VehicleNotOwned && (
        <Field
          id="lpnType"
          name="lpnType"
          label="Vehicle availability"
          labelPosition="left"
          placeholder="Select"
          component={SelectField}
          options={typeLpn.map((item) => ({
            label: item.Value,
            value: item.lpnType
          }))}
          disabled={verifyRoles([roles.SOTR_VIEW])}
        />
      )}
      {values?.IsAdditionalInformationVisible && (
        <Field
          id="lpn"
          name="lpn"
          label="Additional information"
          labelPosition="left"
          component={TextField}
          placeholder="Type here"
          readOnly={verifyRoles([roles.SOTR_VIEW])}
        />
      )}
    </>
  );
};
