import { useAuth } from 'auth/Authorization';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field } from 'formik';
import React from 'react';
import RegisterCourt from 'utils/StaticData/Customer/registerCourt.json';
import RegisterSection from 'utils/StaticData/Customer/registerSection.json';

const CommercialInfo: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  return (
    <>
      <Field
        id="tradeNumber"
        name="tradeNumber"
        placeholder="Type here"
        label="Trade number"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="vatNo"
        name="vatNo"
        placeholder="Type here"
        label="VAT Number"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="vatID"
        name="vatID"
        placeholder="Type here"
        label="VAT ID"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="registerCourt"
        name="registerCourt"
        placeholder="Select"
        label="Register court"
        labelPosition="left"
        component={SelectField}
        options={RegisterCourt.map((item) => ({
          value: item.RegisterCourtTypeID,
          label: item.Value
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="registerInsertNumber"
        name="registerInsertNumber"
        placeholder="Type here"
        label="Register Insert Number"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="registerSection"
        name="registerSection"
        placeholder="Select"
        label="Register section"
        labelPosition="left"
        component={SelectField}
        options={RegisterSection.map((item) => ({
          value: item.RegisterSectionTypeID,
          label: item.Value
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};
export default CommercialInfo;
