import React, { useRef, useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { queryClient } from 'react-query';
import { useAuth } from 'auth/Authorization';
import TollInformation from 'components/TollInformation/TollInformation';
import { errorHelper } from 'utils/helpers/errorHelper';
import { Vehicle } from '../types';
import { VehicleForm } from '../VehicleForm/VehicleForm';
import {
  SEARCH_VEHICLE_QUERY_KEY,
  VEHICLE_DETAILS_QUERY_KEY
} from '../utils/constants';
import { validateVehicleDetails } from '../utils';
import ObuList from '../../OBU/ObuList/ObuList';

interface VehicleDetailsProps {
  vehicleId?: string;
  onSuccess?: () => void;
}

async function fetchVehicleById(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.vehicleDetails,
    {
      vehicleID: id
    }
  );
  return data;
}

const STALE_TIME = 300000;

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  vehicleId,
  onSuccess
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedTab, setSelectedTab] = useState<string>('VehicleDetails');
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: vehicleData,
    isLoading
  } = useQuery({
    queryKey: [VEHICLE_DETAILS_QUERY_KEY, vehicleId],
    queryFn: () => fetchVehicleById(vehicleId ?? ''),
    staleTime: STALE_TIME,
    enabled: !!vehicleId && selectedTab === 'VehicleDetails',
    refetchOnWindowFocus: false
  });

  const handleSave = async (
    values: Vehicle,
    formikHelpers: FormikHelpers<Vehicle>
  ) => {
    if (await validateVehicleWeights(values)) {
      const { setSubmitting } = formikHelpers;
      customerInstance
        .post(customerEndPoints.saveVehicle, values)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [SEARCH_VEHICLE_QUERY_KEY]
          });
          queryClient.invalidateQueries({
            queryKey: [VEHICLE_DETAILS_QUERY_KEY, vehicleId]
          });
          successDialog('', 'Vehicle updated successfully');
        })
        .catch((error) => {
          const message = 'Failed to save Vehicle';
          errorDialog('', errorHelper(error, message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const validateVehicleWeights = async (values: Vehicle) => {
    const { weightF1, grossVehicleWeightRating } = values;
    if (
      weightF1 &&
      grossVehicleWeightRating &&
      weightF1 < grossVehicleWeightRating
    ) {
      const isConfirmed = await confirmCloseDialog(
        'Warning',
        'Vehicle Weight F1 is smaller than weight F2.'
      );
      if (!isConfirmed) {
        return false;
      }
    }
    return true;
  };

  const handleSaveAs = async (values: Vehicle) => {
    if (await validateVehicleWeights(values)) {
      const { vehicleID, ...rest } = values;
      customerInstance
        .post(customerEndPoints.saveVehicle, rest)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [SEARCH_VEHICLE_QUERY_KEY]
          });
          if (onSuccess) {
            onSuccess();
          }

          successDialog('', 'New Vehicle added successfully');
        })
        .catch((error) => {
          const message = 'Failed to add a new Vehicle';
          errorDialog('', errorHelper(error, message));
        });
    }
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Vehicle',
      'Are you sure you want to delete this Vehicle?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteVehicle, { vehicleID: vehicleId })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [SEARCH_VEHICLE_QUERY_KEY]
        });
        queryClient.removeQueries([VEHICLE_DETAILS_QUERY_KEY, vehicleId]);
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'Vehicle deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete Vehicle.';
        errorDialog('', errorHelper(error, message));
      });
  };

  const handleValidateAndSave = () => {
    const { current } = formRef;
    current?.validateForm().then((errors) => {
      if (Object.keys(errors).length) {
        Object.keys(errors).forEach((key) => {
          current?.setFieldTouched(key, true);
        });
      } else {
        handleSaveAs(current?.values as Vehicle);
      }
    });
  };

  const items = [
    {
      label: 'VEHICLE DETAILS',
      key: 'VehicleDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={vehicleData?.data}
              enableReinitialize
              onSubmit={handleSave}
              validate={validateVehicleDetails}
              innerRef={formRef}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      data-testid="save-vehicle"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      onClick={handleValidateAndSave}
                      data-testid="save-as-vehicle"
                    >
                      Save As
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-vehicle"
                      disabled={verifyRoles([
                        roles.SOTR_VIEW,
                        roles.SOTR_MANAGE
                      ])}
                    >
                      Delete
                    </Button>
                  </div>
                  <VehicleForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation id={vehicleId ?? ''} type="vehicle" />
    },
    {
      label: 'LINKED OBU',
      key: 'linkedOBU',
      children: <ObuList vehicleId={vehicleId} />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div className="bg-[#F7F7F7] w-full h-full" data-testid="vehicle-details">
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};
