import React, { ChangeEvent, useRef, useState } from 'react';

import {
  Button,
  Icons,
  Sizes,
  Alignments,
  Variants,
  Text
} from '@sede-x/shell-ds-react-framework';
import {
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikValues
} from 'formik';
import { customerInstance } from 'api';
import { DocumentsEndpoints } from 'api/apiEndpoints';
import { queryClient } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import SelectField from 'components/FormCmponents/SelectField';
import { useParams } from 'react-router-dom';
import { StyledModals, FileInput } from '../styles';
import { DocumentDto, DocumentTypeDto } from '../types';

interface DocumentNewProps {
  type: string;
  id: string;
  filteredDocumentTypes: DocumentTypeDto[];
  disabled?: boolean;
}

const DocumentNew: React.FC<DocumentNewProps> = ({
  type,
  id,
  filteredDocumentTypes,
  disabled
}) => {
  const [openNewDocument, setOpenNewDocument] = useState(false);
  const [fileName, setFileName] = useState('');

  const { errorDialog, successDialog } = useConfirmDialogs();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { customerId } = useParams<{ customerId: string }>();

  const onClose = () => {
    formRef.current?.setFieldValue('fileType', undefined);
    setFileName('');
    setOpenNewDocument(false);
  };

  const handleSave = (
    _values: DocumentDto,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(DocumentsEndpoints.save[type], formRef.current?.values?.payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Documents']
        });
        successDialog('', 'Document added successfully.');
        onClose();
      })
      .catch((error) => {
        const message = 'Failed to save document';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const readUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setFileName(file.name);
      processFile(file);

      event.target.value = ''; // clear the file input
    }
  };

  const processFile = (file: File) => {
    const MAX_FILE_SIZE = 2097152; // 2 MB in bytes
    if (file?.size > MAX_FILE_SIZE) {
      errorDialog('', 'File size exceeds 2 MB. Please choose a smaller file.');
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        const resultString = reader.result as string;

        const base64Data = resultString.replace(/^data:(.*,)?/, '');
        const fileExtension = file?.name?.split('.');

        const basePayload = {
          fileContent: base64Data,
          fileExtension: fileExtension[1],
          fileName: file.name
        };

        const finalPayload =
          type === 'customer'
            ? {
                ...basePayload,
                customerId,
                customerDocumentTypeId: formRef.current?.values?.fileType
              }
            : {
                ...basePayload,
                customerVehicleId: id,
                vehicleDocumentTypeId: formRef.current?.values?.fileType
              };

        formRef.current?.setFieldValue('payload', finalPayload);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Button
        icon={<Icons.Add />}
        size="xsmall"
        variant="transparent"
        onClick={() => setOpenNewDocument(true)}
        data-testid="add-document"
        disabled={disabled}
      >
        New
      </Button>

      <div className="flex h-full">
        <Formik<FormikValues>
          initialValues={{}}
          enableReinitialize
          onSubmit={handleSave}
          innerRef={formRef}
        >
          {({ handleSubmit, isSubmitting }) =>
            openNewDocument && (
              <StyledModals
                title="Document Upload"
                size={Sizes.Small}
                maskClosable={false}
                mask
                contentScrollable
                actionsAlignment={Alignments.Right}
                centered
                open={openNewDocument}
                onClose={onClose}
                zIndex={3}
                actions={[
                  {
                    label: 'CANCEL',
                    action: () => {
                      onClose();
                    },
                    props: {
                      variant: Variants.Outlined
                    }
                  },
                  {
                    label: 'Save',
                    action: () => {
                      handleSubmit();
                    },
                    props: {
                      disabled:
                        isSubmitting ||
                        !fileName ||
                        !formRef.current?.values?.fileType,
                      type: 'submit'
                    }
                  }
                ]}
              >
                <Field
                  id="fileType"
                  name="fileType"
                  placeholder="Select"
                  label="File Type"
                  labelPosition="left"
                  component={SelectField}
                  options={filteredDocumentTypes.map((val) => ({
                    value: val.DocumentTypeID,
                    label: val.Name
                  }))}
                  mandatory
                  getPopupContainer={() => document.body}
                />
                <div className="grid gap-1  md:grid-cols-3 lg:grid-cols-3 p-2">
                  <div />
                  <div className="col-span-2">
                    <Button
                      size="small"
                      className="w-full"
                      data-testid="upload-button"
                      onKeyDown={(e) => {
                        if (
                          (e.key === 'Enter' || e.code === 'Space') &&
                          fileInputRef.current
                        ) {
                          fileInputRef.current.click();
                        }
                      }}
                      disabled={filteredDocumentTypes.length === 0}
                    >
                      <div className="flex gap-2">
                        <div className="">
                          <Icons.UploadCloud width={15} height={15} />
                        </div>
                        <div>Select file</div>
                        <FileInput
                          type="file"
                          id="file-input"
                          data-testid="file-input"
                          name="file-input"
                          onChange={(e) => readUploadFile(e)}
                          tabIndex={-1}
                          ref={fileInputRef}
                          aria-hidden="true"
                        />
                      </div>
                    </Button>
                    <Text size="small">{fileName}</Text>
                  </div>
                </div>
              </StyledModals>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default DocumentNew;
