import { FormikErrors, FormikHelpers } from 'formik';
import { Contact } from '../types';

export const contactValidation = (values: Contact) => {
  const errors: FormikErrors<Contact> = {};

  if (!values.countryTypeID) {
    errors.countryTypeID = 'Please select Country';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter Last Name';
  }

  if (
    values.countryTypeID === '2dfb93b4-027e-4ae4-aa03-3e7138af0223' &&
    !values.belgianRegionId
  ) {
    errors.belgianRegionId = 'Please enter Belgian Region';
  }
  if (
    values.countryTypeID === '7f50cb6e-aecb-4870-90c4-fcb8799ee539' &&
    !values.italianProvinceId
  ) {
    errors.italianProvinceId = 'Please enter Italian Province';
  }

  return errors;
};

/// Checks if both the country calling code and phone number are fullfilled
export const ArePhoneAndCountryCodeFilled = (
  callingCountryCode?: string | null,
  phoneNumber?: string | null
) => {
  if (!callingCountryCode || !phoneNumber) {
    return false;
  }
  return true;
};

// Validates the proper european calling country number
/// <remarks>It accepts formats such as 420, +420, 034, 0034</remarks>
export const CheckCountryCodeFormat = (callingCountryCode?: string | null) => {
  if (!callingCountryCode) {
    return false;
  }
  const pattern = /^((\+\d{2,4})|(\d{2,4}))$/;
  return pattern.test(callingCountryCode);
};

// Validates the proper european phone number format
export const CheckPhoneFormat = (phoneNumber?: string | null) => {
  if (!phoneNumber) {
    return false;
  }
  const pattern = /^[0-9()\s-]{3,16}$/;
  return pattern.test(phoneNumber);
};

const checkPhoneCountryCodeAndPhoneFormat = (
  values: Contact,
  touched: { [key: string]: boolean }
) => {
  const { phoneCountryCode, phone, mobileCountryCode, mobile } = values;
  let text = '';
  if (phoneCountryCode || phone) {
    if (!CheckCountryCodeFormat(phoneCountryCode)) {
      touched.phoneCountryCode = true;
      text = 'Phone country code has an incorrect format!';
    }

    if (!CheckPhoneFormat(phone)) {
      touched.phone = true;
      text = 'Phone number has an incorrect format!';
    }
  }

  if (mobileCountryCode || mobile) {
    if (!CheckCountryCodeFormat(mobileCountryCode)) {
      touched.mobileCountryCode = true;
      text = 'Mobile phone country code has an incorrect format!';
    }

    if (!CheckPhoneFormat(mobile)) {
      touched.mobile = true;
      text = 'Mobile phone number has an incorrect format!';
    }
  }
  return text;
};

export const validateMobilePhone = async (
  values: Contact,
  formikHelpers: FormikHelpers<Contact>
) => {
  const { phone, mobile, phoneCountryCode, mobileCountryCode } = values;

  const { setErrors, setTouched } = formikHelpers;

  let text = '';

  if (
    !ArePhoneAndCountryCodeFilled(phoneCountryCode, phone) &&
    !ArePhoneAndCountryCodeFilled(mobileCountryCode, mobile)
  ) {
    setTouched(
      {
        phone: true,
        mobile: true,
        phoneCountryCode: true,
        mobileCountryCode: true
      },
      false
    );
    setErrors({
      phone: ' ',
      mobile: ' ',
      phoneCountryCode: ' ',
      mobileCountryCode: ' '
    });

    text = 'Either phone number or mobile number must be filled in!';
  } else {
    const touched: { [key: string]: boolean } = {};

    text = checkPhoneCountryCodeAndPhoneFormat(values, touched);

    setTouched(touched, false);
    const errorFields = Object.keys(touched).reduce(
      (acc: { [key: string]: string }, key) => {
        acc[key] = ' ';
        return acc;
      },
      {}
    );
    setErrors(errorFields);
  }

  return text;
};
