import { useAuth } from 'auth/Authorization';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field } from 'formik';
import React from 'react';
import Currency from 'utils/StaticData/Customer/currency.json';

const Details: React.FC = () => {
  const { verifyRoles, roles } = useAuth();

  return (
    <>
      <Field
        id="legalForm"
        name="legalForm"
        placeholder="Type here"
        label="Legal Form"
        labelPosition="left"
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="name"
        name="name"
        placeholder="Type here"
        label="Customer Name 1"
        labelPosition="left"
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="name1"
        name="name1"
        placeholder="Type here"
        label="Customer Name 2"
        labelPosition="left"
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="name2"
        name="name2"
        placeholder="Type here"
        label="Customer Name 3"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="countryTypeID"
        name="countryTypeID"
        placeholder="Select"
        label="Country of residence"
        labelPosition="left"
        component={SelectField}
        dataEndPoint="/country"
        dataField="countryID"
        dataLabelField="name"
        onOption
        required
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="currencyTypeID"
        name="currencyTypeID"
        placeholder="Select"
        label="Currency"
        labelPosition="left"
        component={SelectField}
        options={Currency.map((currency) => ({
          value: currency.CurrencyTypeID,
          label: currency.Code
        }))}
        required
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};

export default Details;
