import React from 'react';
import { Button, Card, Tooltip } from '@sede-x/shell-ds-react-framework';
import { Field, useFormikContext } from 'formik';
import TextField from 'components/FormCmponents/TextField';
import DatePicker from 'components/FormCmponents/DatePicker';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from 'utils/helpers';
import { Label } from 'components/Label/Label';
import { useAuth } from 'auth/Authorization';
import { OBU } from '../types';
import ContractSelection from './ContractSelection';

const ObuForm: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<OBU>();
  const { verifyRoles, roles } = useAuth();

  return (
    <Card>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
        <div>
          <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
            <div>
              <Label text="Contract" />
            </div>
            <div className="col-span-2">
              <ContractSelection isDisabled={verifyRoles([roles.SOTR_VIEW])} />
            </div>
          </div>
        </div>
        <div>
          <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-  items-center p-2 ">
            <div>
              <Label text="Vehicle" />
            </div>
            <div className="col-span-2">
              <div className="flex items-center gap-2 h-full">
                <div className="grow">
                  <Field
                    id="vehicle"
                    name="vehicle"
                    placeholder="Type here"
                    readOnly
                    component={TextField}
                  />
                </div>
                <div>
                  <Tooltip
                    overlay="open"
                    placement="top"
                    trigger={
                      <Link
                        to={`/customer/${values.customerId}/${values.vehicleId}?type=vehicle`}
                        target="_blank"
                      >
                        <Button size="small">Open</Button>
                      </Link>
                    }
                    arrow={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Field
          id="effectiveDate"
          name="effectiveDate"
          placeholder="Type here"
          label="Effective Date"
          labelPosition="left"
          component={DatePicker}
          onChange={(v: dayjs.Dayjs | null) => {
            setFieldValue('effectiveDate', v?.toISOString());
          }}
          disabled={verifyRoles([roles.SOTR_VIEW])}
        />

        <Field
          id="serialNo"
          name="serialNo"
          placeholder="Type here"
          label="Serial Number"
          labelPosition="left"
          component={TextField}
          readOnly={verifyRoles([roles.SOTR_VIEW])}
        />

        <Field
          id="partnerObuNumber"
          name="partnerObuNumber"
          placeholder="Type here"
          readOnly
          label="Partner OBU Number"
          labelPosition="left"
          component={TextField}
        />

        <Field
          id="obuType"
          name="obuType"
          placeholder="Type here"
          readOnly
          label="OBU Type"
          labelPosition="left"
          component={TextField}
        />
        <Field
          id="obuCardNumber"
          name="obuCardNumber"
          placeholder="Type here"
          readOnly
          label="OBU Card Number"
          labelPosition="left"
          component={TextField}
        />

        <Field
          id="partnerObuStatusLongName"
          name="partnerObuStatusLongName"
          placeholder="Type here"
          readOnly
          label="Partner Obu Status"
          labelPosition="left"
          component={TextField}
        />
        <Field
          id="obuOrderDate"
          name="obuOrderDate"
          placeholder="Type here"
          readOnly
          label="OBU Order Date"
          labelPosition="left"
          component={TextField}
          value={
            values.obuOrderDate
              ? dayjs(values.obuOrderDate).format(DEFAULT_DATE_FORMAT)
              : ''
          }
        />
      </div>
    </Card>
  );
};

export default ObuForm;
