import React, { useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import TollInformation from 'components/TollInformation/TollInformation';
import { useAuth } from 'auth/Authorization';
import { OBU } from '../types';
import ObuForm from '../ObuForm/ObuForm';
import { OBU_DETAILS_QUERY_KEY } from '../utils/constants';

interface ObuDetailsProps {
  obuId?: string;
  onSuccess?: () => void;
  cacheKey?: string;
}

async function fetchObuById(id: string) {
  const { data } = await customerInstance.post(customerEndPoints.obuDetails, {
    obuId: id
  });
  return data;
}

const STALE_TIME = 300000;

const ObuDetails: React.FC<ObuDetailsProps> = ({
  obuId: obuID,
  onSuccess,
  cacheKey
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedTab, setSelectedTab] = useState<string>('OBUDetails');
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: obuData,
    isLoading
  } = useQuery({
    queryKey: [OBU_DETAILS_QUERY_KEY, obuID],
    queryFn: () => fetchObuById(obuID ?? ''),
    staleTime: STALE_TIME,
    enabled: !!obuID && selectedTab === 'OBUDetails',
    refetchOnWindowFocus: false
  });

  const handleSave = (values: OBU, formikHelpers: FormikHelpers<OBU>) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.updateObu, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        queryClient.invalidateQueries({
          queryKey: [OBU_DETAILS_QUERY_KEY, obuID]
        });
        successDialog('', 'OBU updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to update OBU detail.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSaveAs = (values: OBU) => {
    const { obuId, ...rest } = values;
    customerInstance
      .post(customerEndPoints.updateObu, rest)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        if (onSuccess) {
          onSuccess();
        }

        successDialog('', 'New OBU added successfully');
      })
      .catch((error) => {
        const message = 'Failed to create new OBU.';
        errorDialog('Error', errorHelper(error, message));
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete OBU',
      'Are you sure you want to delete this OBU?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteObu, { obuID })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        queryClient.removeQueries([OBU_DETAILS_QUERY_KEY, obuID]);
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'OBU deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete OBU.';
        errorDialog('', errorHelper(error, message));
      });
  };

  const items = [
    {
      label: 'OBU DETAILS',
      key: 'OBUDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={obuData?.data ?? {}}
              enableReinitialize
              onSubmit={handleSave}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      data-testid="save-obu"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      onClick={() => handleSaveAs(values)}
                      data-testid="save-as-obu"
                    >
                      Save As
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-obu"
                      disabled={verifyRoles([
                        roles.SOTR_VIEW,
                        roles.SOTR_MANAGE
                      ])}
                    >
                      Delete
                    </Button>
                  </div>
                  <ObuForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation id={obuID ?? ''} type="obu" />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full h-full"
      data-testid="obu-details"
    >
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default ObuDetails;
