import {
  Alignments,
  Button,
  Icons,
  ModalAction,
  Sentiments,
  Sizes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { columnBuilder } from 'utils/helpers';
import { BelgiumOnBlackList } from 'utils/Interfaces';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import QueryError from 'components/QueryError';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { useState } from 'react';
import Table from 'components/Table/Table';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';
import { StyledModal } from './style';

function ObuReportDetails<TData>({
  selectedRows,
  onSuccess,
  disabled
}: Readonly<{
  selectedRows: Array<TData>;
  onSuccess?: () => void;
  disabled?: boolean;
}>) {
  const [open, setOpen] = useState(false);
  const { confirmCloseDialog, errorDialog, successDialog } =
    useConfirmDialogs();

  const payload = selectedRows.map((row) => ({
    serialNumber: (row as BelgiumOnBlackList).serialNumber,
    fuelCard: (row as BelgiumOnBlackList).cardNo
  }));

  const {
    data: response,
    isLoading,
    isError
  } = useQuery(
    ['obuDetails', ...payload],
    () => axiosInstance.post('report/action/belgium/blacklists', payload),
    {
      refetchOnWindowFocus: false,
      enabled: open
    }
  );

  const { belgiumObuBlackListDetails: data, interfaceRunId } =
    response?.data || {};

  const handleReject = async () => {
    const isConfirmed = await confirmCloseDialog(
      selectedRows.length > 1
        ? 'Are you sure you want to reject these records?'
        : 'Are you sure you want to reject this record?',
      selectedRows.length > 1 ? 'Reject the records' : 'Reject the record'
    );
    if (isConfirmed) {
      axiosInstance
        .post('/report/action/belgium/blacklist/reject', interfaceRunId, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
          successDialog(
            '',
            selectedRows.length > 1
              ? 'Records updated successfully'
              : 'Record updated successfully'
          );
          onSuccess?.();
        })
        .catch(() => {
          errorDialog('', 'Failed to update record, please try again later');
        })
        .finally(() => handleOnClose());
    }
  };

  const handleApprove = async () => {
    axiosInstance
      .post('/report/action/belgium/blacklist/approve', interfaceRunId, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        successDialog(
          '',
          selectedRows.length > 1
            ? 'Records updated successfully'
            : 'Record updated successfully'
        );
        onSuccess?.();
      })
      .catch(() => {
        errorDialog('', 'Failed to update record, please try again later');
      })
      .finally(() => handleOnClose());
  };

  const handleOnClose = () => setOpen(false);

  const actions = [
    {
      label: 'Reject',
      action: () => handleReject(),
      props: {
        variant: Variants.Outlined,
        iconPosition: 'left',
        size: 'xsmall',
        sentiment: Sentiments.Negative,
        icon: <Icons.Cross />,
        hidden: isError || isLoading,
        'data-testid': 'reject-button'
      }
    },
    {
      label: 'Approve',
      action: () => handleApprove(),
      props: {
        variant: Variants.Outlined,
        size: 'xsmall',
        iconPosition: 'left',
        sentiment: Sentiments.Positive,
        icon: <Icons.Check />,
        hidden: isError || isLoading,
        'data-testid': 'approve-button'
      }
    }
  ];

  const columns = [
    ...columnBuilder<unknown>(ActionColumns.belgiumObusOnBlackListDetails)
  ];

  return (
    <>
      <Button
        data-testid="next-button"
        disabled={!selectedRows.length || disabled}
        id="nextbutton"
        iconPosition="right"
        icon={<Icons.Play />}
        onClick={() => setOpen(true)}
        size="small"
      >
        Continue
      </Button>
      <StyledModal
        width="70%"
        title="Belgium Blacklisted OBUs"
        open={open}
        size={Sizes.Small}
        maskClosable={false}
        actions={actions as ModalAction[]}
        mask
        onClose={handleOnClose}
        contentScrollable
        actionsAlignment={Alignments.Right}
        centered
        bodyPadding={false}
      >
        <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
          <QueryError isError={isError} isLoading={isLoading}>
            <Table
              columns={columns}
              data={data}
              columnSelection={false}
              exportEnabled={false}
            />
          </QueryError>
        </div>
      </StyledModal>
    </>
  );
}

export default ObuReportDetails;
