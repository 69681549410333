import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { CommentsEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import {
  getOverviewColumns,
  radioactions
} from 'components/Table/columns/overviewColumns';
import React, { useState } from 'react';
import { queryClient } from 'react-query';
import { columnBuilder } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  Alignments,
  Button,
  Icons,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { useAuth } from 'auth/Authorization';
import { StyledModal } from './styles';
import { Comment } from './types';
import CommentDetails from './CommentDetails/CommentDetails';
import CommentNew from './CommentNew/CommentNew';

interface CommentsProps {
  type: string;
  id: string;
}

type Identifier = {
  [key: string]: {
    field: string;
    label: string;
  };
};

const identifier: Identifier = {
  customer: {
    field: 'customerID',
    label: 'Customer ID'
  },
  obu: {
    field: 'obuid',
    label: 'OBU ID'
  },
  contract: {
    field: 'contractID',
    label: 'Contract ID'
  },
  vehicle: {
    field: 'vehicleID',
    label: 'Vehicle ID'
  },
  contact: {
    field: 'contactID',
    label: 'Contact ID'
  },
  card: {
    field: 'cardID',
    label: 'Card ID'
  },
  address: {
    field: 'addressID',
    label: 'Address ID'
  }
};

async function fetchComments(
  type: string,
  id: string,
  pageNumber: number,
  size: number
) {
  const { data } = await customerInstance.post(CommentsEndpoints.get[type], {
    id,
    pageNumber,
    pageSize: size
  });
  return data;
}

const DEFAULT_PAGE_SIZE = 20;

const Comments: React.FC<CommentsProps> = ({ type, id }) => {
  const { verifyRoles, roles } = useAuth();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [selectedRow, setSelectedRow] = useState<Comment[] | null>(null);

  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const { isError, isLoading, data } = useQuery({
    queryKey: ['Comments', id, type, pageNumber, pageSize],
    queryFn: () => fetchComments(type, id, pageNumber, pageSize),
    cacheTime: 0,
    refetchOnWindowFocus: 'always',
    enabled: !!id
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  const customColumn = columnBuilder([identifier[type]]);
  const { data: commentsData, count } = data || {};

  const handleDoubleClick = (row: Row<Comment>) => {
    const comment = row.original;
    setSelectedComment(comment);
    setIsOpenDetails(true);
  };

  const onClose = () => {
    setIsOpenDetails(false);
    setSelectedComment(null);
  };

  const handleDelete = async () => {
    const [comment] = selectedRow ?? [];
    const isConfirmed = await confirmCloseDialog(
      'Delete Comment',
      'Are you sure you want to delete the selected comment?'
    );
    if (!isConfirmed) {
      return;
    }
    customerInstance
      .post(CommentsEndpoints.delete[type], {
        commentID: comment.commentID
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Comments']
        });
        queryClient.removeQueries(['comment-details', comment.commentID]);
        successDialog('', 'Comment deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete Comment.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSelectedRow(null);
      });
  };

  return (
    <div className=" bg-white p-4 h-full">
      <div className="flex  flex-col h-full">
        <QueryError isLoading={isLoading} isError={isError}>
          <div className="flex gap-2 justify-end p-2">
            <div className="flex items-center gap-2">
              <CommentNew
                type={type}
                id={id}
                disabled={verifyRoles([roles.SOTR_VIEW])}
              />
              <Button
                icon={<Icons.TrashClear />}
                size="xsmall"
                variant="transparent"
                onClick={handleDelete}
                disabled={
                  !selectedRow?.length ||
                  verifyRoles([roles.SOTR_VIEW, roles.SOTR_MANAGE])
                }
                style={{
                  display: !commentsData?.length ? 'none' : ''
                }}
                data-testid="delete-comment"
              >
                Delete
              </Button>
            </div>
          </div>
          <div className="bg-[#F0F0F0] h-full pb-3 overflow-y-auto flex flex-col">
            <ExpandableTable<Comment>
              data={commentsData}
              columns={
                verifyRoles([roles.SOTR_VIEW])
                  ? ([
                      ...customColumn,
                      ...getOverviewColumns('Comments')
                    ] as ColumnDef<Comment>[])
                  : ([
                      ...radioactions,
                      ...customColumn,
                      ...getOverviewColumns('Comments')
                    ] as ColumnDef<Comment>[])
              }
              stickyColumns={['actions']}
              columnSelection={false}
              exportEnabled={false}
              paginationData={{ ...paginationProps, total: count }}
              onRowDoubleClick={handleDoubleClick}
              onRowClick={(row) => {
                if (verifyRoles([roles.SOTR_VIEW])) {
                  return;
                }
                setSelectedRow([row.original]);
              }}
              enableMultiRowSelection={false}
              selectedRows={{
                [selectedRow?.[0]?.commentID as string]: true
              }}
              getRowId={(row) => row.commentID as string}
            />
          </div>
        </QueryError>
      </div>

      {isOpenDetails && (
        <StyledModal
          title="Comment"
          size={Sizes.Small}
          maskClosable={false}
          mask
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          open={isOpenDetails}
          onClose={onClose}
          zIndex={3}
        >
          <CommentDetails
            commentId={selectedComment?.commentID ?? ''}
            type={type}
            onSuccess={onClose}
          />
        </StyledModal>
      )}
    </div>
  );
};

export default Comments;
