import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import {
  getOverviewColumns,
  detailAction
} from 'components/Table/columns/overviewColumns';
import { useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { Text } from '@sede-x/shell-ds-react-framework';
import { Row } from '@tanstack/react-table';
import { customerEndPoints } from 'api/apiEndpoints';
import { Drawer } from 'components/Drawer/Drawer';
import { useAuth } from 'auth/Authorization';
import AddressDetails from '../AddressDetails/AddressDetails';
import { Address } from '../types';
import AddAddress from '../AddAddress/AddAddress';

interface CustomerDataProps {
  customerId: string;
}
export interface SearchPayload {
  pageNumber: number;
  pageSize: number;
  customerId?: string;
  requestId?: string;
}
const DEFAULT_PAGE_SIZE = 20;

const AddressList: React.FC<CustomerDataProps> = ({ customerId }) => {
  const { verifyRoles, roles } = useAuth();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  const { data, isLoading, isError } = useQuery(
    ['address-list', customerId, pageNumber, pageSize],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.addressList,
        {
          pageNumber,
          pageSize,
          customerId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {}
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const handleClickDetails = (row: Row<unknown>) => {
    setSelectedAddress(row.original as Address);
    setOpenDetails(true);
  };

  const handleOnClose = () => {
    setOpenDetails(false);
    setSelectedAddress(null);
  };

  const detailIcons = detailAction(handleClickDetails);

  const paginationProps = useSdsPagination(handleChangePagination);

  return (
    <div
      className="flex flex-col grow overflow-y-auto pb-3 gap-2 bg-shellExtraPaleGrey2"
      data-testid="address-list"
    >
      <div className=" bg-white p-5">
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            Addresses
          </Text>
          <div className="flex justify-end gap-3">
            <AddAddress
              customerId={customerId}
              disabled={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable
          data={data?.data ?? []}
          columns={[...detailIcons, ...getOverviewColumns('Addresses')]}
          columnSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          exportEnabled={false}
          stickyColumns={['expandAction']}
          selectedRows={{
            [selectedAddress?.addressID as string]: true
          }}
          getRowId={(row) => (row as Address).addressID as string}
        />
      </QueryError>
      <Drawer
        open={openDetails}
        header=" "
        mask={false}
        onClose={handleOnClose}
        width="70%"
        bodyPadding={false}
        sticky
      >
        <div className="flex grow h-full">
          <AddressDetails
            addressID={selectedAddress?.addressID}
            onSuccess={handleOnClose}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default AddressList;
