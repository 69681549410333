import SelectField from 'components/FormCmponents/SelectField';
import { Field } from 'formik';
import { useAuth } from 'auth/Authorization';
import ChassisTypes from 'utils/StaticData/Vehicle/chassisType.json';
import CabinTypes from 'utils/StaticData/Vehicle/cabinType.json';
import EuroVehicleSubGroupTypes from 'utils/StaticData/Vehicle/euroVehicleSubGroup.json';
import co2ClassTypes from 'utils/StaticData/Vehicle/co2ClassType.json';
import NumberField from 'components/FormCmponents/NumberField';

export const Co2Tolling = () => {
  const { verifyRoles, roles } = useAuth();

  return (
    <>
      <Field
        id="drivenAxlesNumber"
        name="drivenAxlesNumber"
        label="Driven Axels Number"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="chassisTypeID"
        name="chassisTypeID"
        label="Chassis type"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        options={ChassisTypes.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="cabinTypeID"
        name="cabinTypeID"
        label="Cabin type"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        options={CabinTypes.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="enginePower"
        name="enginePower"
        label="Engine power[kw]"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="engineCapacity"
        name="engineCapacity"
        label="Engine capacity[kw]"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="euroVehicleSubGroupTypeId"
        name="euroVehicleSubGroupTypeId"
        label="Euro Vehicle Sub Group"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        options={EuroVehicleSubGroupTypes.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="co2EmissionValueLoad"
        name="co2EmissionValueLoad"
        label="CO2 emission [g/t*km]"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
        decimalAllowed
      />
      <Field
        id="cop"
        name="cop"
        label="CO2 emission [g/km]"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
        decimalAllowed
      />
      <Field
        id="carbonDioxideClassTypeId"
        name="carbonDioxideClassTypeId"
        label="Carbon dioxide class type"
        labelPosition="left"
        component={SelectField}
        disabled
        options={co2ClassTypes.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
      />
      <Field
        id="fuelTankCapacity"
        name="fuelTankCapacity"
        label="Fuel Tank Capacity"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};
