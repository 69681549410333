import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import DatePicker from 'components/FormCmponents/DatePicker';
import dayjs from 'dayjs';
import { Field, Formik, FormikErrors, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { useState, KeyboardEvent } from 'react';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import { DEFAULT_DATE_FORMAT } from 'utils/helpers';
import NumberField from 'components/FormCmponents/NumberField';

interface FeeFormProps {
  type: number;
  rentalId?: string;
  data?: RentalFeePeriod;
  disableBtn?: boolean;
}

interface RentalFeePeriod {
  fromDate?: string;
  toDate?: string;
  value?: string;
}
const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
  ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(event.key) &&
    event.preventDefault();
};

const RentalFeeForm: React.FC<FeeFormProps> = ({
  rentalId,
  type,
  data,
  disableBtn
}) => {
  const [open, setOpen] = useState(false);
  const { errorDialog, successDialog } = useConfirmDialogs();

  const validate = (values: RentalFeePeriod) => {
    const errors: FormikErrors<RentalFeePeriod> = {};
    const mandatoryMsg = 'Please fill out this mandatory field';

    if (!values.fromDate) {
      errors.fromDate = mandatoryMsg;
    }
    if (!values.toDate) {
      errors.toDate = mandatoryMsg;
    }
    if (!values.value) {
      errors.toDate = mandatoryMsg;
    }

    return errors;
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleSave = (
    values: RentalFeePeriod,
    { setSubmitting }: FormikHelpers<RentalFeePeriod>
  ) => {
    customerInstance
      .post(customerEndPoints.saveRentalPeriod, {
        rentalId,
        type,
        ...values
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['rental-period-lists']
        });
        successDialog('', 'Successfully added new Rental fee period');
        handleOnClose();
      })
      .catch((error) => {
        const message =
          'Failed to add new Rental fee period, please try again later';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Button
        data-testid="add-new-rental-fee"
        onClick={() => setOpen(true)}
        fullWidth
        size="small"
        disabled={disableBtn}
      >
        Select Fee period
      </Button>
      {open && (
        <Formik<RentalFeePeriod>
          initialValues={data ?? {}}
          enableReinitialize
          validate={validate}
          onSubmit={handleSave}
        >
          {({
            handleSubmit,
            values,
            isSubmitting,
            setFieldValue,
            isValid,
            dirty
          }) => (
            <Modal
              title="Rental Fee Period"
              open={open}
              width="570px"
              size={Sizes.Small}
              maskClosable={false}
              mask
              onClose={handleOnClose}
              contentScrollable
              actionsAlignment={Alignments.Right}
              className="!overflow-visible"
              centered
              actions={[
                {
                  label: 'Save',
                  action: () => {
                    handleSubmit();
                  },
                  props: {
                    icon: <Icons.Save />,
                    disabled: !(isValid && dirty) || isSubmitting
                  }
                }
              ]}
              zIndex={3}
            >
              <div>
                <Field
                  id="fromDate"
                  name="fromDate"
                  placeholder="Type here"
                  label="From Date"
                  labelPosition="left"
                  component={DatePicker}
                  format={DEFAULT_DATE_FORMAT}
                  onChange={(v: dayjs.Dayjs | null) => {
                    setFieldValue('fromDate', v?.toISOString());
                  }}
                  disabledDate={(current: dayjs.Dayjs) =>
                    values.toDate && current.isAfter(dayjs(values.toDate))
                  }
                />
                <Field
                  id="toDate"
                  name="toDate"
                  placeholder="Type here"
                  label="To Date"
                  labelPosition="left"
                  component={DatePicker}
                  format={DEFAULT_DATE_FORMAT}
                  onChange={(v: dayjs.Dayjs | null) => {
                    setFieldValue('toDate', v?.toISOString());
                  }}
                  disabledDate={(current: dayjs.Dayjs) =>
                    values.fromDate && current.isBefore(dayjs(values.fromDate))
                  }
                />
                <Field
                  id="value"
                  name="value"
                  placeholder="Type here"
                  label="Value"
                  labelPosition="left"
                  component={NumberField}
                  onKeyDown={blockInvalidChar}
                />
              </div>
            </Modal>
          )}
        </Formik>
      )}
    </>
  );
};

export default RentalFeeForm;
