import { TextInput } from '@sede-x/shell-ds-react-framework';
import TextField from 'components/FormCmponents/TextField';
import { Field, useFormikContext } from 'formik';
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Label } from 'components/Label/Label';
import FormLabel from 'components/FormCmponents/FormLabel';
import { useQueries } from '@tanstack/react-query';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { STALE_TIME } from 'react-query';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import NumberField from 'components/FormCmponents/NumberField';
import { useAuth } from 'auth/Authorization';
import RentalFeeForm from './RentalFeeForm';
import { Contract } from '../types';

dayjs.extend(isBetween);

interface RentalFeeProps {
  rentalId?: string;
  contractData?: Contract;
}

const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
  ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(event.key) &&
    event.preventDefault();
};

const T4ERentalFee: React.FC<RentalFeeProps> = ({ rentalId, contractData }) => {
  const { verifyRoles, roles } = useAuth();
  const types = [0, 1];
  const { values, setFieldValue } = useFormikContext<Contract>();
  const [currentValue, setCurrentValue] = useState<string>();
  const [npCurrentValue, setNpCurrentValue] = useState<string>();

  const results = useQueries({
    queries: types.map((id) => ({
      queryKey: ['rental-period-lists', rentalId, id],
      queryFn: async () => {
        const result = await customerInstance.post(
          customerEndPoints.rentalPeriodList,
          {
            rentalId: id === 0 ? values?.rentalFeesId : values?.rentalFeesNPId,
            type: id
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        return result.data;
      },
      staleTime: STALE_TIME,
      enabled: !!values?.rentalFeesNPId
    }))
  });
  const [{ data: rentalFeeData }, { data: npRentalFeeData }] = results;

  useEffect(() => {
    if (rentalFeeData?.data) {
      const responseData = rentalFeeData?.data;
      const targetDate = dayjs();
      const fromDate = dayjs(responseData?.fromDate);
      const toDate = dayjs(responseData?.toDate);

      const hasRentalFeePeriod = dayjs(targetDate).isBetween(fromDate, toDate);
      setCurrentValue(
        hasRentalFeePeriod ? responseData?.value : values.rentalFee1
      );
    } else {
      setCurrentValue(String(values.rentalFee1));
    }
    if (npRentalFeeData?.data) {
      const npResponseData = npRentalFeeData?.data;
      const targetDate = dayjs();
      const fromDate = dayjs(npResponseData?.fromDate);
      const toDate = dayjs(npResponseData?.toDate);

      const hasNpRentalFeePeriod = dayjs(targetDate).isBetween(
        fromDate,
        toDate
      );
      setNpCurrentValue(
        hasNpRentalFeePeriod ? npResponseData?.value : values.rentalFeeNP
      );
    } else {
      setNpCurrentValue(String(values.rentalFeeNP));
    }
  }, [rentalFeeData, npRentalFeeData, contractData]);

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
        <Field
          id="currencyCode"
          name="currencyCode"
          placeholder="Type here"
          label="Fees Currency"
          labelPosition="left"
          component={TextField}
          readOnly
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
        <section>
          <div className="border-4 border-shellExtraPaleGrey2">
            <div className="p-2">
              <strong>
                <Label text="Rental Fees" />
              </strong>
            </div>
            <div>
              <Field
                id="rentalFee1"
                name="rentalFee1"
                placeholder="Type here"
                label="T4E Default"
                labelPosition="left"
                component={NumberField}
                onKeyDown={blockInvalidChar}
                mandatory
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('rentalFee1', e.target.value);
                  setFieldValue('rentalFeeNP', e.target.value);
                }}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <FormLabel label="T4E Current" labelPosition="left">
                <TextInput
                  id="rentalFeeCurrent"
                  name="rentalFeeCurrent"
                  placeholder="Type here"
                  value={currentValue}
                  data-testid="rentalFeeCurrent"
                  readOnly={verifyRoles([roles.SOTR_VIEW])}
                />
              </FormLabel>
              <FormLabel label="Rental Fee Period" labelPosition="left">
                <RentalFeeForm
                  type={0}
                  rentalId={values?.rentalFeesId}
                  data={rentalFeeData?.data}
                  disableBtn={
                    !contractData?.rentalFee1 || verifyRoles([roles.SOTR_VIEW])
                  }
                />
              </FormLabel>
              <Field
                id="rentalFee3"
                name="rentalFee3"
                placeholder="Type here"
                label="Not Used 3+ months Fees"
                labelPosition="left"
                onKeyDown={blockInvalidChar}
                component={NumberField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
            </div>
          </div>
        </section>
        <section>
          <div className="border-4 border-shellExtraPaleGrey2">
            <div className="p-2">
              <strong>
                <Label text="Rental Fees for never personalised OBUs" />
              </strong>
            </div>
            <div>
              <Field
                id="rentalFeeNP"
                name="rentalFeeNP"
                placeholder="Type here"
                label="T4E Never Personalised Default"
                onKeyDown={blockInvalidChar}
                labelPosition="left"
                component={NumberField}
                mandatory
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <FormLabel
                label="T4E Never Personalised Current"
                labelPosition="left"
              >
                <TextInput
                  id="npRentalFeeCurrent"
                  name="npRentalFeeCurrent"
                  placeholder="Type here"
                  value={npCurrentValue}
                  data-testid="npRentalFeeCurrent"
                  readOnly={verifyRoles([roles.SOTR_VIEW])}
                />
              </FormLabel>
              <FormLabel
                label="Never Personalised OBUs Rental Fee Period"
                labelPosition="left"
              >
                <RentalFeeForm
                  type={1}
                  rentalId={values.rentalFeesNPId}
                  data={npRentalFeeData?.data}
                  disableBtn={
                    !contractData?.rentalFeeNP || verifyRoles([roles.SOTR_VIEW])
                  }
                />
              </FormLabel>

              <Field
                id="spareOBUsCount"
                name="spareOBUsCount"
                placeholder="Type here"
                label="T4E Number of OBUs to be Excluded"
                labelPosition="left"
                component={NumberField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default T4ERentalFee;
