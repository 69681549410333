import React, { useState } from 'react';

import {
  Button,
  Icons,
  Sizes,
  Alignments
} from '@sede-x/shell-ds-react-framework';
import { Field, Formik, FormikHelpers } from 'formik';
import { customerInstance } from 'api';
import { CommentsEndpoints } from 'api/apiEndpoints';
import { queryClient } from 'react-query';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import TextArea from 'components/FormCmponents/TextArea';
import { StyledModal } from '../styles';
import { Comment } from '../types';

interface CommentNewProps {
  type: string;
  id: string;
  disabled?: boolean;
}
type Identifier = {
  [key: string]: string;
};

const identifier: Identifier = {
  customer: 'customerID',
  obu: 'obuid',
  contract: 'contractID',
  vehicle: 'vehicleID',
  contact: 'contactID',
  card: 'cardID',
  address: 'addressID'
};

const CommentNew: React.FC<CommentNewProps> = ({ type, id, disabled }) => {
  const [openNewComment, setOpenNewComment] = useState(false);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const onClose = () => {
    setOpenNewComment(false);
  };

  const handleSave = (
    values: Comment,
    formikHelpers: FormikHelpers<Comment>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(CommentsEndpoints.save[type], { ...values, [identifier[type]]: id })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Comments']
        });
        successDialog('', 'Comment added successfully.');
        onClose();
      })
      .catch((error) => {
        const message = 'Failed to save comment';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Button
        icon={<Icons.Add />}
        size="xsmall"
        variant="transparent"
        onClick={() => setOpenNewComment(true)}
        data-testid="add-comment"
        disabled={disabled}
      >
        New
      </Button>

      {openNewComment && (
        <StyledModal
          title="New Comment"
          size={Sizes.Small}
          maskClosable={false}
          mask
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          open={openNewComment}
          onClose={onClose}
          zIndex={3}
        >
          <div className="flex h-full">
            <Formik<Comment>
              initialValues={{}}
              enableReinitialize
              onSubmit={handleSave}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={isSubmitting || !values.message}
                      data-testid="save-comment"
                    >
                      Save
                    </Button>
                  </div>
                  <Field
                    id="message"
                    name="message"
                    placeholder="Type here"
                    component={TextArea}
                    rows={6}
                    resize="none"
                  />
                </form>
              )}
            </Formik>
          </div>
        </StyledModal>
      )}
    </div>
  );
};

export default CommentNew;
