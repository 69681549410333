import SelectField from 'components/FormCmponents/SelectField';
import { useAuth } from 'auth/Authorization';
import { Field } from 'formik';
import TyreTypes from 'utils/StaticData/Vehicle/tyreType.json';
import NumberField from 'components/FormCmponents/NumberField';

export const Dimensions = () => {
  const { verifyRoles, roles } = useAuth();
  return (
    <>
      <Field
        id="length"
        name="length"
        label="Length(mm)"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="height"
        name="height"
        label="Height(mm)"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="width"
        name="width"
        label="Width(mm)"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="firstAxleHeight"
        name="firstAxleHeight"
        label="First Axle Height(mm)"
        labelPosition="left"
        component={NumberField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="tyreTypeID"
        name="tyreTypeID"
        label="Tyre type"
        labelPosition="left"
        component={SelectField}
        options={TyreTypes.map((item) => ({
          label: item.Name,
          value: item.Id
        }))}
        placeholder="Select"
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};
