import DatePicker from 'components/FormCmponents/DatePicker';
import TextField from 'components/FormCmponents/TextField';
import { Label } from 'components/Label/Label';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import SelectField from 'components/FormCmponents/SelectField';
import { useAuth } from 'auth/Authorization';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Customer } from '../types';
import ShellConsortiumMember from './ShellConsortiumMember/ShellConsortiumMember';

const DATE_FORMAT = 'M/D//YYYY h:mm:ss A';
const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';

const ShellConsortiumInfo: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { values, setFieldValue } = useFormikContext<Customer>();

  const { confirmCloseDialog } = useConfirmDialogs();

  return (
    <>
      <section>
        <div>
          <div className="p-2">
            <strong>
              <Label text="EU License 1" />
            </strong>
          </div>
          <Field
            id="euLicense"
            name="euLicense"
            placeholder="Type here"
            label="EU License Number"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="euLicenseIssueDate"
            name="euLicenseIssueDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License issue date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue('euLicenseIssueDate', v?.format(DATE_FORMAT));
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="euLicenseStartDate"
            name="euLicenseStartDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License validity start date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue('euLicenseStartDate', v?.format(DATE_FORMAT));
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="euLicenseExpiryDate"
            name="euLicenseExpiryDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License expiry date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue('euLicenseExpiryDate', v?.format(DATE_FORMAT));
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
        </div>
      </section>
      <section>
        <div>
          <div className="p-2">
            <strong>
              <Label text="EU License 2" />
            </strong>
          </div>
          <Field
            id="initialEuLicense"
            name="initialEuLicense"
            placeholder="Type here"
            label="EU License Number"
            labelPosition="left"
            component={TextField}
            readOnly={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="initialEuLicenseIssueDate"
            name="initialEuLicenseIssueDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License issue date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue(
                'initialEuLicenseIssueDate',
                v?.format(DATE_FORMAT)
              );
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="initialEuLicenseStartDate"
            name="initialEuLicenseStartDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License validity start date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue(
                'initialEuLicenseStartDate',
                v?.format(DATE_FORMAT)
              );
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <Field
            id="initialEuLicenseExpiryDate"
            name="initialEuLicenseExpiryDate"
            format={DATE_DISPLAY_FORMAT}
            placeholder="DD/MM/YYYY"
            label="License expiry date"
            labelPosition="left"
            component={DatePicker}
            onChange={(v: dayjs.Dayjs | null) => {
              setFieldValue(
                'initialEuLicenseExpiryDate',
                v?.format(DATE_FORMAT)
              );
            }}
            disabled={verifyRoles([roles.SOTR_VIEW])}
          />
          <ShellConsortiumMember />
          <Field
            id="isResellerMember"
            name="isResellerMember"
            placeholder="Select"
            label="Reseller Member of Shell Consortium"
            labelPosition="left"
            component={SelectField}
            options={[
              {
                value: 1,
                label: 'Reseller is member of Shell Consortium'
              },
              {
                value: 2,
                label: 'Reseller is not member of Shell Consortium'
              },
              {
                value: 3,
                label: 'Not reseller'
              }
            ]}
            disabled={verifyRoles([roles.SOTR_VIEW])}
            onChange={async (value: number) => {
              if (value === 1 || value === 2) {
                const isConfirmed = await confirmCloseDialog(
                  'Reseller Member of Shell Consortium',
                  'Are you sure you want to set this customer as a reseller?. \nPlease note that once done, this cannot be changed back.'
                );
                if (!isConfirmed) {
                  return;
                }
              }
              setFieldValue('isResellerMember', value);
            }}
          />

          <section>
            <div className="border-4 border-shellExtraPaleGrey2 shadow-xl">
              <div className="p-2">
                <strong>
                  <Label text="Reseller info" />
                </strong>
              </div>
              <Field
                id="memberName"
                name="memberName"
                placeholder="Type here"
                label="Member Name"
                disabled={values.isResellerMember !== 2}
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberVATID"
                name="memberVATID"
                placeholder="Type here"
                label="Member VAT ID"
                disabled={values.isResellerMember !== 2}
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberStreet"
                name="memberStreet"
                placeholder="Type here"
                disabled={values.isResellerMember !== 2}
                label="Member Street"
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberStreetNumber"
                name="memberStreetNumber"
                placeholder="Type here"
                disabled={values.isResellerMember !== 2}
                label="Member Street Number"
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberCity"
                name="memberCity"
                placeholder="Type here"
                disabled={values.isResellerMember !== 2}
                label="Member City"
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberZIP"
                name="memberZIP"
                placeholder="Type here"
                disabled={values.isResellerMember !== 2}
                label="Member ZIP"
                labelPosition="left"
                component={TextField}
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
              <Field
                id="memberCountry"
                name="memberCountry"
                placeholder="Type here"
                disabled={
                  values.isResellerMember !== 2 ||
                  verifyRoles([roles.SOTR_VIEW])
                }
                label="Member Country"
                labelPosition="left"
                component={SelectField}
                dataEndPoint="/country"
                dataField="countryID"
                dataLabelField="name"
                onOption
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
export default ShellConsortiumInfo;
