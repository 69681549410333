import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { SelectedMenu } from 'components/SideMenu/types';
import { useStaticData } from 'hooks/useStaticData';
import { ProvidersData, TollMap, TollObject } from 'Pages/Providers/types';
import { AuthContext, IAuthContext } from 'auth';
import {
  createExportAllRequest,
  filterReportMenuBasedOnRoles,
  getReportDetails
} from 'utils/helpers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { exportAllEndpoints } from 'api/apiEndpoints';
import { ParamsType } from 'components/ContentHeader/types';
import { useReport } from 'hooks/useReports';
import { ReportContainer } from 'Pages/Reports/style';
import SideMenu from 'components/SideMenu/SideMenu';
import ReportsMenuUnselected from 'components/ReportsContent/ReportsMenuUnselected';
import ReportsDetails from 'components/ReportsContent/ReportsDetails';
import ActionFilters from 'assets/filters/ActionFilters.json';
import { getActionColumn } from 'components/Table/columns/actionColumns';
import { useAuth } from 'auth/Authorization';
import { ReportsMenu } from './ReportMenu';
import ActionSection from './ActionSection';

const SUCCESS_CODE = 200;

const TollReports = () => {
  const { verifyRoles, roles } = useAuth();
  const [menu, setMenu] = useState<string>('');
  const [menuLabel, setMenuLabel] = useState<string>('');
  const [exportAllEnabled, setExportAllEnabled] = useState<boolean>();
  const [exportEnabled, setExportEnabled] = useState<boolean>();
  const [enableMultiRowSelection, setEnableMultiRowSelection] =
    useState<boolean>();
  const [params, setParams] = useState<ParamsType>({});
  const [tollMap, setTollMap] = useState<TollMap>({});
  const [parentMenu, setParentMenu] = useState<string>('');
  const [reportName, setReportName] = useState<string>('');
  const { tokenData } = useContext<IAuthContext>(AuthContext);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const { data: userRoles } = useStaticData(
    'userroles',
    'Auth/userroles',
    !!tokenData?.mail,
    null,
    { retryOnMount: false }
  );

  const {
    mutate: getReport,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useReport(menu);
  const { data: tollData } = useStaticData('toll', 'toll', true);

  const [selectedRows, setSelectedRows] = useState<Array<ProvidersData>>([]);

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    setSelectedRows([]);
    getReport({
      pageNumber,
      pageSize: newPageSize,
      tollId: tollMap[parentMenu]?.tollId,
      ...params
    });
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  useEffect(() => {
    if (tollData) {
      const obj: TollMap = {};
      tollData.data.forEach((tollObj: TollObject) => {
        obj[tollObj.name] = tollObj;
      });
      setTollMap(obj);
    }
  }, [tollData]);

  useLayoutEffect(() => {
    setParams({});
    resetPagination();
    resetData();
    setSelectedRows([]);
  }, [menu]);

  const getReportsData = () => {
    setSelectedRows([]);
    resetPagination();
    getReport({
      pageNumber: 1,
      pageSize: 20,
      tollId: tollMap[parentMenu]?.tollId,
      ...params
    });
  };

  const handleMenuClick = (selectedMenu: SelectedMenu) => {
    const {
      keyPath,
      key,
      label,
      exportAll,
      reportName: name,
      multiRowSelection,
      exportEnabled: downloadEnabled
    } = selectedMenu;
    setMenu(key);
    setParentMenu(keyPath[keyPath.length - 1]);
    setMenuLabel(label);
    setExportAllEnabled(exportAll);
    setEnableMultiRowSelection(multiRowSelection);
    setExportEnabled(downloadEnabled);
    name && setReportName(name);
  };

  const onClickExportAll = (errors?: ParamsType) => {
    if (errors && Object.keys(errors).length) {
      errorDialog('', 'Please enter correct data.');
    } else {
      const reportsDetails = getReportDetails(
        reportName,
        tollMap[parentMenu]?.tollId,
        userRoles
      );
      const requestPayload = {
        reportName,
        reportID: reportsDetails?.reportID,
        eventType: reportsDetails?.eventType ?? reportsDetails?.reportName,
        reportType: reportName,
        tollId: tollMap[parentMenu]?.tollId,
        ...params
      };

      createExportAllRequest(
        exportAllEndpoints[menu] ?? exportAllEndpoints.exportAll,
        requestPayload
      )
        .then((response) => {
          if (response.status === SUCCESS_CODE) {
            successDialog(
              '',
              'Your download request has been received. Notifications will be updated as soon as the download link is available. Thank you for your patience!'
            );
          }
        })
        .catch(() => {
          errorDialog(
            '',
            'Your download request has been failed. Please try again.'
          );
        });
    }
  };

  let columns = getActionColumn(menu);

  if (verifyRoles([roles.SOTR_VIEW])) {
    columns = columns?.filter((column) => column.id !== 'actions');
  }

  return (
    <ReportContainer className="flex p-3">
      <div className="flex flex-shrink-0 flex-col bg-white mr-2 overflow-y-scroll no-scrollbar">
        <SideMenu
          setMenu={handleMenuClick}
          menuItems={filterReportMenuBasedOnRoles(ReportsMenu, userRoles)}
          searchEnabled
        />
      </div>
      <div className="mr-2 flex flex-col flex-grow overflow-auto h-full ">
        {!menu ? (
          <ReportsMenuUnselected
            welcomeTitle="Welcome to Reports"
            content="In this section you can find specific reports and can filter and export
          them, Please select the report from left hand side menu."
          />
        ) : (
          <div className="flex flex-col h-full gap-1">
            <div className="grow overflow-auto">
              <ReportsDetails<ProvidersData>
                title={menuLabel}
                menuKey={menu}
                onButtonClick={getReportsData}
                data={data?.data}
                cols={columns}
                params={params}
                isLoading={isLoading}
                isError={isError}
                setParams={setParams}
                paginationProps={{
                  ...paginationProps,
                  total: data?.totalNumberOfRecords
                }}
                filterObject={{ ...ActionFilters }}
                onClickExportAll={onClickExportAll}
                exportAllEnabled={exportAllEnabled}
                exportEnabled={exportEnabled}
                onSelectedRowsChange={setSelectedRows}
                enableMultiRowSelection={enableMultiRowSelection}
              />
            </div>
            {data && (
              <ActionSection
                menu={menu}
                selectedRows={selectedRows}
                onSuccess={getReportsData}
                tollId={tollMap[parentMenu]?.tollId}
              />
            )}
          </div>
        )}
      </div>
    </ReportContainer>
  );
};

export default TollReports;
