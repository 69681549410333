import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import {
  getOverviewColumns,
  detailAction
} from 'components/Table/columns/overviewColumns';
import { useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { Text } from '@sede-x/shell-ds-react-framework';
import { Row } from '@tanstack/react-table';
import { Drawer } from 'components/Drawer/Drawer';
import { customerEndPoints } from 'api/apiEndpoints';
import { useAuth } from 'auth/Authorization';
import { Contract } from '../types';
import ContractDetails from '../ContractDetails/ContractDetails';
import ContractNew from '../ContractNew/ContractNew';

interface CustomerDataProps {
  customerId: string;
}
export interface SearchPayload {
  pageNumber: number;
  pageSize: number;
  customerId?: string;
  requestId?: string;
}
const DEFAULT_PAGE_SIZE = 20;

const ContractList: React.FC<CustomerDataProps> = ({ customerId }) => {
  const { verifyRoles, roles } = useAuth();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    null
  );

  const { data, isLoading, isError } = useQuery(
    ['customercontracts-list', customerId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        customerEndPoints.contractList,
        {
          pageNumber,
          pageSize,
          customerId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: 'always'
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  const handleClickDetails = (row: Row<unknown>) => {
    setSelectedContract(row.original as Contract);
    setOpenDetails(true);
  };

  const handleOnClose = () => {
    setOpenDetails(false);
    setSelectedContract(null);
  };

  const detailIcons = detailAction(handleClickDetails);

  return (
    <div
      className="flex flex-col grow overflow-y-auto pb-3 gap-2 bg-shellExtraPaleGrey2"
      data-testid="contracts-list"
    >
      <div className=" bg-white p-5">
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            Contracts
          </Text>
          <div className="flex justify-end gap-3">
            <ContractNew
              customerId={customerId}
              disabled={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable
          data={data?.data ?? []}
          columns={[...detailIcons, ...getOverviewColumns('Contracts')]}
          columnSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          exportEnabled={false}
          stickyColumns={['expandAction']}
          selectedRows={{
            [selectedContract?.contractID as string]: true
          }}
          getRowId={(row) => (row as Contract).contractID as string}
        />
      </QueryError>
      <Drawer
        open={openDetails}
        header="Contract details"
        mask={false}
        onClose={handleOnClose}
        width="70%"
        bodyPadding={false}
        sticky
      >
        <div className="flex grow h-full">
          <ContractDetails
            contractID={selectedContract?.contractID}
            onSuccess={handleOnClose}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ContractList;
