import React, { useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers } from 'formik';
import TollInformation from 'components/TollInformation/TollInformation';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { queryClient, STALE_TIME } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import { useAuth } from 'auth/Authorization';
import { Contact } from '../types';
import ContactForm from '../ContactForm/ContactForm';
import { contactValidation, validateMobilePhone } from '../utils/formUtils';

interface ContactDetailsProps {
  contactID?: string;
  onSuccess?: () => void;
  cacheKey?: string;
}

async function fetchContactById(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.contactDetails,
    {
      contactID: id
    }
  );
  return data;
}
const CONTACT_GET_DETAIL_QUERY = 'get-contact-detail';

const ContactDetails: React.FC<ContactDetailsProps> = ({
  contactID: contactId,
  onSuccess,
  cacheKey
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedTab, setSelectedTab] = useState<string>('ContactDetails');
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: contactData,
    isLoading
  } = useQuery({
    queryKey: [CONTACT_GET_DETAIL_QUERY, contactId],
    queryFn: () => fetchContactById(contactId ?? ''),
    staleTime: STALE_TIME,
    enabled: !!contactId && selectedTab === 'ContactDetails',
    refetchOnWindowFocus: false
  });

  const handleSave = async (
    values: Contact,
    formikHelpers: FormikHelpers<Contact>
  ) => {
    const phoneValidationText = await validateMobilePhone(
      values,
      formikHelpers
    );
    if (phoneValidationText) {
      const isConfirmed = await confirmCloseDialog(
        'Warning',
        `${phoneValidationText}<br>\nDo you want to proceed anyway?`
      );
      if (!isConfirmed) {
        return;
      }
    }
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.updateContact, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        queryClient.invalidateQueries({
          queryKey: [CONTACT_GET_DETAIL_QUERY, contactId]
        });
        successDialog('', 'Contact updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to save Contact';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSaveAs = (values: Contact) => {
    const { contactID, ...rest } = values;
    customerInstance
      .post(customerEndPoints.updateContact, rest)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        if (onSuccess) {
          onSuccess();
        }

        successDialog('', 'New Contact added successfully');
      })
      .catch((error) => {
        const message = 'Failed to add a new Contact';
        errorDialog('Error', errorHelper(error, message));
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Contact',
      'Are you sure you want to delete this Contact?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteContact, { contactId })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey]
        });
        queryClient.removeQueries([CONTACT_GET_DETAIL_QUERY, contactId]);
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'Contact deleted successfully');
      })
      .catch((error) => {
        const message = 'Failed to delete Contact.';
        errorDialog('', errorHelper(error, message));
      });
  };

  const items = [
    {
      label: 'CONTACT DETAILS',
      key: 'ContactDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={contactData?.data}
              enableReinitialize
              onSubmit={handleSave}
              validate={contactValidation}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      data-testid="save-contact"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      disabled={isSubmitting || verifyRoles([roles.SOTR_VIEW])}
                      onClick={() => handleSaveAs(values)}
                      data-testid="save-as-contact"
                    >
                      Save As
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-contact"
                      disabled={verifyRoles([
                        roles.SOTR_VIEW,
                        roles.SOTR_MANAGE
                      ])}
                    >
                      Delete
                    </Button>
                  </div>
                  <ContactForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation id={contactId ?? ''} type="contact" />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full"
      data-testid="contact-details"
    >
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default ContactDetails;
