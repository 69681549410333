import { SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';
import styled from 'styled-components';
import FormLabel from './FormLabel';

dayjs.extend(weekday);
dayjs.extend(localeData);

const StyledDatePicker = styled(SingleDatePicker)`
  .shell-date-picker-disabled {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(28, 28, 28, 0.14);
    input {
      -webkit-text-fill-color: rgba(52, 52, 52, 1) !important;
    }
  }
`;

const DatePicker = (props: FieldProps) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <StyledDatePicker
          elevation
          inputReadOnly
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentElement as HTMLElement
          }
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-datepicker`}
          value={field.value ? dayjs(field.value) : null}
          onBlur={field.onBlur}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
    </div>
  );
};
export default DatePicker;
