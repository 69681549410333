import React from 'react';
import { CommentsByIdEndpoints, CommentsEndpoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Field, Formik, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { STALE_TIME, queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import TextArea from 'components/FormCmponents/TextArea';
import { useAuth } from 'auth/Authorization';
import { Comment } from '../types';

interface CommentDetailsProps {
  commentId: string;
  onSuccess?: () => void;
  type: string;
  add?: boolean;
}

async function fetchCommentById(id: string, type: string) {
  const { data } = await customerInstance.post(CommentsByIdEndpoints[type], {
    commentID: id
  });
  return data;
}

const CommentDetails: React.FC<CommentDetailsProps> = ({
  commentId,
  onSuccess,
  type,
  add = false
}) => {
  const { verifyRoles, roles } = useAuth();
  const { errorDialog, successDialog } = useConfirmDialogs();

  const {
    isError,
    data: commentData,
    isLoading
  } = useQuery({
    queryKey: ['comment-details', commentId],
    queryFn: () => fetchCommentById(commentId, type),
    staleTime: STALE_TIME,
    enabled: !!commentId && !add,
    refetchOnWindowFocus: 'always'
  });

  const handleSave = (
    values: Comment,
    formikHelpers: FormikHelpers<Comment>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(CommentsEndpoints.save[type], values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Comments']
        });
        queryClient.invalidateQueries({
          queryKey: ['comment-details', commentId]
        });
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'Comment updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to update Comment.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSaveAs = (values: Comment) => {
    const { commentID, ...rest } = values;
    customerInstance
      .post(CommentsEndpoints.save[type], rest)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Comments']
        });
        if (onSuccess) {
          onSuccess();
        }
        successDialog('', 'New Comment created successfully');
      })
      .catch((error) => {
        const message = 'Failed to create new Comment.';
        errorDialog('Error', errorHelper(error, message));
      });
  };

  return (
    <div className="flex h-full">
      <QueryError isError={isError} isLoading={isLoading && !add}>
        <Formik<Comment>
          initialValues={commentData ?? {}}
          enableReinitialize
          onSubmit={handleSave}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form className="p-2 w-full" onSubmit={handleSubmit}>
              <div className="flex justify-end mb-2">
                <Button
                  icon={<Icons.Save />}
                  size="xsmall"
                  variant="transparent"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    values.message === '' ||
                    verifyRoles([roles.SOTR_VIEW])
                  }
                  data-testid="save-comment"
                >
                  Save
                </Button>

                <Button
                  icon={<Icons.Save />}
                  size="xsmall"
                  variant="transparent"
                  disabled={
                    isSubmitting ||
                    values.message === '' ||
                    verifyRoles([roles.SOTR_VIEW])
                  }
                  onClick={() => handleSaveAs(values)}
                  data-testid="save-as-comment"
                >
                  Save As
                </Button>
              </div>
              <Field
                id="message"
                name="message"
                placeholder="Type here"
                component={TextArea}
                rows={6}
                resize="none"
                readOnly={verifyRoles([roles.SOTR_VIEW])}
              />
            </form>
          )}
        </Formik>
      </QueryError>
    </div>
  );
};

export default CommentDetails;
