import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { TollSpecficEndpoints } from 'api/apiEndpoints';
import React, { useCallback, useState } from 'react';
import { queryClient } from 'react-query';
import QueryError from 'components/QueryError';
import {
  Button,
  Flags,
  Icons,
  Label,
  Pecten
} from '@sede-x/shell-ds-react-framework';
import { useParams } from 'react-router-dom';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { useAuth } from 'auth/Authorization';
import { StyledAccordion, StyledAvatar } from './styles';
import AutoPay from '../../../assets/icons/autopay_icon.png';
import T4EIcon from '../../../assets/icons/t4e_icon.png';
import TelepassIcon from '../../../assets/icons/telepass_icon.png';
import { ParameterDto, UpdateTollSpecificDto } from './types';
import AccordionContent from './AccordionContent';

interface TollSpecificProps {
  type: string;
  id: string;
}

type Identifier = {
  [key: string]: string;
};

export const identifier: Identifier = {
  customer: 'customerId',
  obu: 'obuId',
  contract: 'contractId',
  vehicle: 'vehicleId',
  contact: 'contactId'
};

const ShellPecton = () => <Pecten size="small" />;

const tollIcons: { [key: string]: JSX.Element } = {
  Belarus: <Flags.By />,
  Belgium: <Flags.Be />,
  CzechRepublic: <Flags.Cz />,
  CzechToll: <Flags.Cz />,
  eMobility: <ShellPecton />,
  eVignette: <Flags.Lu />,
  FDE: <Flags.Earth />,
  France: <Flags.Fr />,
  Germany: <Flags.De />,
  GFN: <ShellPecton />,
  Poland: <Flags.Pl />,
  Poland_eToll: <Flags.Pl />,
  Slovakia: <Flags.Sk />,
  Autopay: <StyledAvatar size="small" src={AutoPay} />,
  T4E: <StyledAvatar size="small" src={T4EIcon} />,
  Telepass: <StyledAvatar size="small" src={TelepassIcon} />
};

async function fetchTollSpcific(type: string, id: string, customerId: string) {
  const payload = {
    [identifier[type]]: id
  };
  if (customerId) {
    payload.customerId = customerId;
  }
  const { data } = await customerInstance.post(
    TollSpecficEndpoints.get[type],
    payload
  );
  return data;
}

const RenderAccordionLabel = ({ label }: { label: string }) => (
  <div className="flex items-center">
    <div className="mr-2">{tollIcons[label] ?? <ShellPecton />}</div>
    <Label>{label}</Label>
  </div>
);

const TollSpecific: React.FC<TollSpecificProps> = ({ id, type }) => {
  const { verifyRoles, roles } = useAuth();
  const { customerId } = useParams<{ customerId: string; id?: string }>();
  const [updateDataList, setUpdateDataList] = useState<UpdateTollSpecificDto[]>(
    []
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const { isError, isLoading, data } = useQuery({
    queryKey: ['toll-specific', id, type, customerId],
    queryFn: () => fetchTollSpcific(type, id, customerId ?? ''),
    cacheTime: 0,
    refetchOnWindowFocus: 'always',
    enabled: !!id
  });

  const { data: tollSPecficData } = data || {};

  const handleUpdateDataList = useCallback(
    (updatedData: UpdateTollSpecificDto[]) => {
      setUpdateDataList(updatedData);
    },
    [updateDataList]
  );

  const handleUpdate = () => {
    setIsSubmitting(true);

    const payload = updateDataList.map((item) => {
      const { tollName, ...rest } = item;
      return rest;
    });

    customerInstance
      .post(TollSpecficEndpoints.update[type], {
        updateDataLists: payload,
        customerId
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['toll-specific', id, type, customerId]
        });
        successDialog('', 'Toll Specific details updated successfully.');
      })
      .catch((error) => {
        const message = 'Failed to update Toll Specific details.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className=" bg-white p-5 h-full">
      <div className="flex  gap-2 flex-col h-full">
        <QueryError isLoading={isLoading} isError={isError}>
          <div className="flex gap-2 justify-end p-2 ">
            <div className="flex items-center gap-2">
              <Button
                icon={<Icons.Save />}
                size="xsmall"
                variant="transparent"
                onClick={handleUpdate}
                disabled={
                  !updateDataList?.length || verifyRoles([roles.SOTR_VIEW])
                }
                data-testid="update-toll-specific"
                loading={isSubmitting}
                hidden={!tollSPecficData?.parametervalues}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="bg-[#F0F0F0] h-full overflow-y-auto flex flex-col p-3">
            {tollSPecficData?.parametervalues ? (
              <StyledAccordion
                fullWidth
                iconSet="chevron"
                borders
                items={Object.keys(tollSPecficData?.parametervalues).map(
                  (key) => ({
                    key,
                    label: <RenderAccordionLabel label={key} />,
                    children: (
                      <AccordionContent
                        parameters={
                          tollSPecficData?.parametervalues[
                            key
                          ] as ParameterDto[]
                        }
                        dropdownValue={
                          tollSPecficData.parameterDropDownValues?.[key] || []
                        }
                        type={type}
                        id={id}
                        updateDataList={updateDataList}
                        onEdit={handleUpdateDataList}
                        tollName={key}
                      />
                    ),
                    className: 'bg-[#FFFFFF] mb-3'
                  })
                )}
              />
            ) : (
              <div className="flex justify-center items-center h-full">
                <Label>No data found</Label>
              </div>
            )}
          </div>
        </QueryError>
      </div>
    </div>
  );
};

export default TollSpecific;
