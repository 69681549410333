import React, { ChangeEvent } from 'react';
import { Field, useFormikContext } from 'formik';
import TextField from 'components/FormCmponents/TextField';
import DatePicker from 'components/FormCmponents/DatePicker';
import NumberField from 'components/FormCmponents/NumberField';
import dayjs from 'dayjs';
import CheckBox from 'components/FormCmponents/CheckBox';
import { DEFAULT_DATE_FORMAT } from 'utils/helpers';
import { useAuth } from 'auth/Authorization';
import { CardType } from '../types';

const CardForm: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { values, setFieldValue } = useFormikContext<CardType>();

  function handleChangeCardNumber(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, maxLength } = event.target;
    const v = maxLength > 0 ? value.slice(0, maxLength) : value;
    setFieldValue(name, v);
  }

  return (
    <div className="bg-white p-5">
      <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
        <Field
          id="cardNo"
          name="cardNo"
          placeholder="Type here"
          label="Card Number"
          labelPosition="left"
          component={NumberField}
          maxLength={19}
          mandatory
          onChange={handleChangeCardNumber}
          readOnly={verifyRoles([roles.SOTR_VIEW])}
        />
        <Field
          id="licensePlate"
          name="licensePlate"
          placeholder="Type here"
          label="License Plate"
          labelPosition="left"
          component={TextField}
          readOnly={verifyRoles([roles.SOTR_VIEW])}
        />
        <Field
          id="validFrom"
          name="validFrom"
          placeholder="Type here"
          label="Valid From"
          labelPosition="left"
          component={DatePicker}
          format={DEFAULT_DATE_FORMAT}
          onChange={(v: dayjs.Dayjs | null) => {
            setFieldValue('validFrom', v?.toISOString());
          }}
          disabledDate={(current: dayjs.Dayjs) =>
            values.validTo && current.isAfter(dayjs(values.validTo))
          }
          mandatory
          disabled={verifyRoles([roles.SOTR_VIEW])}
        />
        <Field
          id="validTo"
          name="validTo"
          placeholder="Type here"
          label="Valid To"
          labelPosition="left"
          component={DatePicker}
          format={DEFAULT_DATE_FORMAT}
          onChange={(v: dayjs.Dayjs | null) => {
            setFieldValue('validTo', v?.toISOString());
          }}
          disabledDate={(current: dayjs.Dayjs) =>
            values.validFrom && current.isBefore(dayjs(values.validFrom))
          }
          mandatory
          disabled={verifyRoles([roles.SOTR_VIEW])}
        />

        <Field
          id="blocked"
          name="blocked"
          label="Is Blocked"
          labelPosition="left"
          component={CheckBox}
          checked={values.blocked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            setFieldValue('blocked', checked);
          }}
          disabled={verifyRoles([roles.SOTR_VIEW])}
        />
      </div>
    </div>
  );
};

export default CardForm;
