import {
  Customers,
  SlovakiaUnknownContracts
} from 'utils/Interfaces/Providers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { axiosInstance } from 'api';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import { createColumnHelper } from '@tanstack/react-table';
import { Flexbox, RadioButton, Sizes } from '@sede-x/shell-ds-react-framework';
import { columnBuilder } from 'utils/helpers';
import { AxiosError } from 'axios';
import { BEError } from 'utils/Interfaces';
import CustomerSearch from '../CustomerSearch/CustomerSearch';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';

const columnHelper = createColumnHelper<Customers>();
const customerColumns = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  }),
  ...columnBuilder<Customers>(ActionColumns.unknownContractCustomers)
];

function AssignCustomer<TData>({
  selectedRows,
  onSuccess,
  disabled
}: Readonly<{
  selectedRows: Array<TData>;
  onSuccess?: () => void;
  disabled?: boolean;
}>) {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const handleAssignCustomer = (selectedCustomer?: Customers) => {
    const [selectedContract] = selectedRows;

    const payload = {
      cardNo: (selectedContract as SlovakiaUnknownContracts).cardNo,
      customerId: selectedCustomer?.customerId
    };

    axiosInstance
      .post(actionButtonEndpoints.slovakiaUnknownContractsAssign, payload)
      .then((_response) => {
        successDialog('', 'Record updated successfully');
        onSuccess?.();
      })
      .catch((error: AxiosError) => {
        let message = 'Failed to assign customer, please try again later';
        const { messages: errorMessage } = error.response?.data as BEError;
        if (
          errorMessage &&
          Array.isArray(errorMessage) &&
          errorMessage.length
        ) {
          message = errorMessage.join(', ');
        }
        errorDialog('', message);
      });
  };
  return (
    <div data-testid="slovakiaUnknownContract-ActionSection">
      <CustomerSearch
        onSubmit={handleAssignCustomer}
        title="Assign Customer"
        triggerComponent="button"
        disabled={!selectedRows.length || disabled}
        label="customer"
        endpoint={actionButtonEndpoints.slovakiaUnknownContractsCustomer}
        columns={customerColumns}
      />
    </div>
  );
}

export default AssignCustomer;
