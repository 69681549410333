import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field, useFormikContext } from 'formik';
import VehicleType from 'utils/StaticData/Vehicle/vehicleType.json';
import VehicleCategory from 'utils/StaticData/Vehicle/vehicleCategory.json';
import Fuel from 'utils/StaticData/Vehicle/fuel.json';
import Emission from 'utils/StaticData/Vehicle/emission.json';
import Manufacturer from 'utils/StaticData/Vehicle/manufacturer.json';
import TrailerType from 'utils/StaticData/Vehicle/trailerType.json';
import Table from 'components/Table/Table';
import { useAuth } from 'auth/Authorization';
import { columns } from 'components/Table/columns/overviewColumns';
import { Row } from '@tanstack/react-table';
import { ChangeEvent } from 'react';
import { Vehicle } from '../types';

interface FuelRowData {
  Name: string;
  Description: string;
  FuelTypeID: string;
}

interface EmissionRowData {
  Name: string;
  Description: string;
  EmissionTypeID: string;
}

const FuelTypeTable = (closeModal: () => void) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Table
      data={Fuel}
      onRowClick={(row) => {
        setFieldValue(
          'fuelTypeID',
          (row as Row<FuelRowData>).original.FuelTypeID
        );
        closeModal();
      }}
      columnSelection={false}
      columns={columns.Fuel}
      exportEnabled={false}
    />
  );
};

const EmissionTypeTable = (closeModal: () => void) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Table
      data={Emission}
      onRowClick={(row) => {
        setFieldValue(
          'emissionTypeID',
          (row as Row<EmissionRowData>).original.EmissionTypeID
        );
        closeModal();
      }}
      columnSelection={false}
      columns={columns.Emission}
      exportEnabled={false}
    />
  );
};

export const MainInfo = () => {
  const { setFieldValue } = useFormikContext<Vehicle>();
  const { verifyRoles, roles } = useAuth();

  return (
    <>
      <Field
        id="carRegistration"
        name="carRegistration"
        label="License Plate"
        labelPosition="left"
        placeholder="Type here"
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setFieldValue('carRegistration', value);
          // Remove all space and convert to uppercase
          setFieldValue(
            'cleanLicensePlate',
            value.replace(/\s/g, '').toUpperCase()
          );
        }}
      />
      <Field
        id="countryTypeID"
        name="countryTypeID"
        placeholder="Select"
        label="Country"
        labelPosition="left"
        component={SelectField}
        dataEndPoint="/country"
        dataField="countryID"
        dataLabelField="name"
        onOption
        mandatory
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="vehicleTypeID"
        name="vehicleTypeID"
        label="Vehicle Type"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        mandatory
        options={VehicleType.map((item) => ({
          label: item.LongName,
          value: item.VehicleTypeID
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="categoryTypeID"
        name="categoryTypeID"
        label="Vehicle Category"
        labelPosition="left"
        mandatory
        component={SelectField}
        placeholder="Select"
        options={VehicleCategory.map((item) => ({
          label: item.LongName,
          value: item.CategoryTypeID
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="fuelTypeID"
        name="fuelTypeID"
        label="Fuel"
        mandatory
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        options={Fuel.map((item) => ({
          label: item.FuelName,
          value: item.FuelTypeID
        }))}
        modalContent={!verifyRoles([roles.SOTR_VIEW]) && FuelTypeTable}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="emissionTypeID"
        name="emissionTypeID"
        label="Emission"
        labelPosition="left"
        mandatory
        component={SelectField}
        placeholder="Select"
        options={Emission.map((item) => ({
          label: item.Name,
          value: item.EmissionTypeID
        }))}
        modalContent={!verifyRoles([roles.SOTR_VIEW]) && EmissionTypeTable}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="manufacturer"
        name="manufacturer"
        label="Manufacturer"
        labelPosition="left"
        mandatory
        component={SelectField}
        placeholder="Select"
        options={Manufacturer.map((item) => ({
          label: item.Name,
          value: item.VehicleModelTypeID
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="trailerTypeID"
        name="trailerTypeID"
        label="Trailer Type"
        labelPosition="left"
        mandatory
        component={SelectField}
        placeholder="Select"
        options={TrailerType.map((item) => ({
          label: item.Name,
          value: item.TrailerTypeID
        }))}
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="vin"
        name="vin"
        label="Vehicle Identification Number (VIN)"
        labelPosition="left"
        mandatory
        component={TextField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="vehicleOwner"
        name="vehicleOwner"
        label="Vehicle Owner"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};
