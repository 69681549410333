import React, { useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { queryClient, STALE_TIME } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import TollInformation from 'components/TollInformation/TollInformation';
import { useAuth } from 'auth/Authorization';
import { CardType } from '../types';
import CardForm from '../CardForm/CardForm';
import CardUsage from '../CardUsage/CardUsage';
import { CardValidate } from '../utils';

interface CardDetailsProps {
  cardID?: string;
  onSuccess: () => void;
  customerID: string;
}

async function fetchCardById(id: string) {
  const { data } = await customerInstance.post(customerEndPoints.cardDetails, {
    cardID: id
  });
  return data;
}
const CARD_LIST_QKEY = 'card-list';
const CardDetails: React.FC<CardDetailsProps> = ({
  cardID: cardId,
  customerID,
  onSuccess
}) => {
  const { verifyRoles, roles } = useAuth();
  const [selectedTab, setSelectedTab] = useState<string>('CardDetails');
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: cardData,
    isLoading
  } = useQuery({
    queryKey: ['card-details', cardId],
    queryFn: () => fetchCardById(cardId ?? ''),
    enabled: !!cardId && selectedTab === 'CardDetails',
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME
  });

  const handleSave = (
    values: CardType,
    formikHelpers: FormikHelpers<CardType>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.updateCard, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [CARD_LIST_QKEY]
        });
        queryClient.invalidateQueries({
          queryKey: ['card-details', cardId]
        });
        successDialog('', 'Card updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to update Card. Please try again.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Card',
      'Are you sure you want to delete this Card?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteCard, { cardID: cardId })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [CARD_LIST_QKEY] });
        onSuccess();
        successDialog('', 'Card deleted successfully');
      })
      .catch(() => {
        errorDialog('', 'Failed to delete Card');
      });
  };

  const items = [
    {
      label: 'CARD DETAILS',
      key: 'CardDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={cardData?.data}
              validate={CardValidate}
              enableReinitialize
              onSubmit={handleSave}
            >
              {({ handleSubmit, isSubmitting, isValid }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        verifyRoles([roles.SOTR_VIEW])
                      }
                      data-testid="save-card"
                    >
                      Save
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-card"
                      disabled={verifyRoles([
                        roles.SOTR_VIEW,
                        roles.SOTR_MANAGE
                      ])}
                    >
                      Delete
                    </Button>
                  </div>
                  <CardForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation type="card" id={cardId ?? ''} />
    },
    {
      label: 'CARD USAGE',
      key: 'cardUsage',
      children: <CardUsage cardID={cardId ?? ''} customerId={customerID} />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full h-full"
      data-testid="card-details"
    >
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default CardDetails;
