import { useAuth } from 'auth/Authorization';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Label } from 'components/Label/Label';
import { Field, useFormikContext } from 'formik';
import useCustomerColco from 'hooks/useCustomerColco';
import React from 'react';
import { Country } from 'utils/Interfaces/StaticData';
import Issuer from 'utils/StaticData/Customer/issuer.json';
import Types from 'utils/StaticData/Customer/types.json';

const MainInfo: React.FC = () => {
  const { verifyRoles, roles } = useAuth();
  const { setFieldValue } = useFormikContext();

  const [colcoMap] = useCustomerColco();

  // filter colco map with only iskeyCountry is true
  const colcoMapFiltered = Object.keys(colcoMap).reduce(
    (acc: { [key: string]: Country }, key: string) => {
      if (colcoMap[key].iskeyCountry) {
        acc[key] = colcoMap[key];
      }
      return acc;
    },
    {}
  );

  return (
    <>
      <Field
        id="customerTypeID"
        name="customerTypeID"
        placeholder="Select"
        label="Type"
        labelPosition="left"
        component={SelectField}
        options={Types.map((type) => ({
          value: type.CustomerTypeID,
          label: type.Name
        }))}
        required
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="issuerTypeID"
        name="issuerTypeID"
        placeholder="Select"
        label="Issuer"
        labelPosition="left"
        component={SelectField}
        options={Issuer.map((issuer) => ({
          value: issuer.IssuerTypeID.toLocaleLowerCase(),
          label: issuer.Name
        }))}
        required
        disabled={verifyRoles([roles.SOTR_VIEW])}
      />
      <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
        <div>
          <Label text="Shell Colco" mandatory />
        </div>
        <div className="grid grid-cols-5 gap-3 col-span-2">
          <Field
            id="colcoCountryNo"
            name="colcoCountryNo"
            component={TextField}
            readOnly
          />
          <div className="col-span-4">
            <Field
              id="colcoCountryTypeID"
              name="colcoCountryTypeID"
              placeholder="Select"
              labelPosition=""
              component={SelectField}
              options={Object.keys(colcoMapFiltered).map((key) => ({
                value: key,
                label: colcoMapFiltered[key].name
              }))}
              onChange={(value: string) => {
                setFieldValue('colcoCountryTypeID', value);
                setFieldValue(
                  'colcoCountryNo',
                  colcoMapFiltered[value]?.shellCountryCode
                );
              }}
              getPopupContainer={() => document.body}
              disabled={verifyRoles([roles.SOTR_VIEW])}
            />
          </div>
        </div>
      </div>

      <Field
        id="customerNo"
        name="customerNo"
        placeholder="Type here"
        label="Customer No"
        labelPosition="left"
        maxLength={8}
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="gfnid"
        name="gfnid"
        placeholder="Type here"
        label="GFN ID"
        labelPosition="left"
        component={TextField}
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
      <Field
        id="payer"
        name="payer"
        placeholder="Type here"
        label="Payer"
        labelPosition="left"
        maxLength={10}
        component={TextField}
        required
        readOnly={verifyRoles([roles.SOTR_VIEW])}
      />
    </>
  );
};

export default MainInfo;
