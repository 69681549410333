import { TextInput } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import { KeyboardEvent } from 'react';
import FormLabel from './FormLabel';

interface CustomNumberFieldProps {
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  decimalAllowed?: boolean;
}

const NumberField = (props: FieldProps & CustomNumberFieldProps) => {
  const {
    field,
    form: { touched, errors },
    onKeyDown,
    decimalAllowed,
    ...rest
  } = props;

  const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
    const notAllowedKeys = decimalAllowed
      ? ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp']
      : ['e', 'E', '+', '-', '.', 'ArrowDown', 'ArrowUp'];
    notAllowedKeys.includes(event.key) && event.preventDefault();
  };

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <TextInput
          {...field}
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          type="number"
          onKeyDown={onKeyDown ?? blockInvalidChar}
          data-testid={`${field.name}-field`}
          autoComplete="off"
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
    </div>
  );
};

export default NumberField;
