import React, { useContext } from 'react';
import { useStaticData } from 'hooks/useStaticData';
import { IAuthContext } from './Types';
import { AuthContext } from './AuthContext';

const AUTH_ENABLED = process.env.REACT_APP_AUTH_ENABLED ?? 'true';

interface AuthorizationProps {
  allowedRolesGroup: string[];
  children: React.ReactNode;
}

export const Roles = {
  SOTR_VIEW: '1', // View
  SOTR_MANAGE: '2', // create, update, modify
  SOTR_SUPER_USER: '3', // create, update, modify, delete
  SOTR_ADMIN: '4'
};

const Authorization: React.FC<AuthorizationProps> = ({
  allowedRolesGroup,
  children
}) => {
  const { error, isLoading, userData } = useAuth();
  if (AUTH_ENABLED === 'false') {
    return <>{children}</>;
  }
  if (isLoading || error || !userData?.accessPermissionGroupCode) {
    return null;
  }

  const permissionGroupCode = userData?.accessPermissionGroupCode;

  const isAuthorized = checkPermission(
    String(permissionGroupCode),
    allowedRolesGroup
  );

  return isAuthorized ? <>{children}</> : null;
};

export default Authorization;

function checkPermission(groupCode: string, allowedRolesGroup: string[]) {
  return allowedRolesGroup?.includes(groupCode);
}

export const useAuth = () => {
  const { tokenData } = useContext<IAuthContext>(AuthContext);
  const {
    error,
    isLoading,
    data: userData
  } = useStaticData('userroles', 'Auth/userroles', !!tokenData?.mail, null, {
    retryOnMount: false
  });

  const verifyRoles = (allowedRolesGroup: string[]) => {
    if (AUTH_ENABLED === 'false') {
      return false;
    }

    if (isLoading || error || !userData?.accessPermissionGroupCode) {
      return false;
    }

    const permissionGroupCode = userData?.accessPermissionGroupCode;

    return checkPermission(String(permissionGroupCode), allowedRolesGroup);
  };
  return { error, isLoading, userData, verifyRoles, roles: Roles };
};
